import { localStorageKeys } from "@/_services/utils"
import get from "lodash.get"
import set from "lodash/set"

// roots keys are URLs' matched path, for instance /contracts/:id
// then inside each you can have anything that describes the user interface state of the rendered route
const uiState = {}

function initUiState() {
  try {
    Object.assign(uiState, JSON.parse(localStorage.getItem(localStorageKeys.UI_STATE.ROOT)))
  } catch (err) {
    // do nothing
  }
}

function getUiState(path) {
  return get(uiState, path)
}

function setUiState(path, value) {
  set(uiState, path, value)
  localStorage.setItem(localStorageKeys.UI_STATE.ROOT, JSON.stringify(uiState))
}

export { getUiState, initUiState, setUiState }
