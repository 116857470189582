import axios from "axios"
import set from "lodash/set"

import { runScriptAndCacheResponse } from "@/_services/cache"
import { getEntityServerRoute } from "basikon-common-utils"
import { axiosOrPush } from "@/_services/offlineService"
import { runScriptLocally } from "@/_services/scripts"
import { addOops } from "@/_services/notification"
import { getTaxes } from "@/_services/coreUtils"
import { debug } from "@/_services/utils"

export async function getAsset(registration) {
  try {
    const asset = (await axios.get(`/api/asset/assets/${registration}`)).data
    return asset
  } catch (error) {
    addOops(error)
  }
}

export async function getAssetVatRate(taxCode) {
  let rate
  try {
    const taxes = await getTaxes()
    rate = taxes?.find(it => it.code === taxCode)?.value
  } catch (error) {
    addOops(error)
  }
  // no rate means no tax
  if (!rate) rate = 0
  return rate
}

export async function getAssetImages(registration, entity = "asset") {
  if (!registration) return

  let imageDocuments = []
  try {
    imageDocuments = (await axios.get(`${getEntityServerRoute(entity)}/${registration}/documents?type=MAINIMAGE,IMAGE&sort=-type,sortIndex`)).data
  } catch (error) {
    addOops(error)
  }

  if (imageDocuments.length) {
    // Fetch main image / first image content
    let index = imageDocuments.findIndex(doc => doc.type === "MAINIMAGE")
    if (index === -1) index = 0

    const imageDocument = imageDocuments[index]
    if (imageDocument && !imageDocument.url && !imageDocument.base64data)
      imageDocuments[index].base64data = await getAssetDocumentBase64data(registration, imageDocument._id, entity)

    // Show first images which have their base64data
    imageDocuments.sort((doc1, doc2) => (doc1.base64data ? -1 : doc2.base64data ? 1 : 0))
  }
  return imageDocuments
}

export async function getAssetDocumentBase64data(registration, documentId, entity = "asset") {
  if (!registration || !documentId) return

  try {
    const base64data = (await axios.get(`${getEntityServerRoute(entity)}/${registration}/documents/${documentId}/content?base64=true`)).data
    return base64data
  } catch (error) {
    addOops(error)
    throw error
  }
}

export async function saveAssetImages(asset) {
  const { registration, deletedImages = [], images = [] } = asset || {}
  if (!registration) return

  const imagesPromises = []
  for (let i = 0; i < deletedImages?.length; i++) {
    imagesPromises.push(
      axiosOrPush({
        method: "delete",
        url: `/api/asset/assets/${registration}/documents/${deletedImages[i]._id}`,
      }),
    )
  }

  for (let i = 0; i < images?.length; i++) {
    const image = images[i]

    if (image.isUpdated) {
      imagesPromises.push(
        axiosOrPush({
          method: "patch",
          url: `/api/asset/assets/${registration}/documents/${image._id}`,
          data: { ...image, base64data: undefined }, // Save bandwidth, don't send base64data.
        }),
      )
    }

    if (!image._id && image.name) {
      imagesPromises.push(
        axiosOrPush({
          method: "post",
          url: `/api/asset/assets/${registration}/documents`,
          data: image,
        }),
      )
    }
  }

  await Promise.all(imagesPromises)
}

export async function saveAssetDepreciationSchedules(asset) {
  const { registration, deletedDepreciationSchedules = [], depreciationSchedules = [] } = asset || {}
  if (!registration) return

  const depreciationSchedulesPromises = []
  for (let i = 0; i < deletedDepreciationSchedules?.length; i++) {
    depreciationSchedulesPromises.push(
      axiosOrPush({
        method: "delete",
        url: `/api/asset/depreciation-schedule/${deletedDepreciationSchedules[i].registration}`,
      }),
    )
  }

  for (let i = 0; i < depreciationSchedules?.length; i++) {
    const depreciationSchedule = depreciationSchedules[i]
    if (!depreciationSchedule.registration || depreciationSchedule.isUpdated) {
      depreciationSchedulesPromises.push(
        axiosOrPush({
          method: "post",
          url: "/api/asset/depreciation-schedule",
          data: { assetRegistration: registration, ...depreciationSchedule },
        }),
      )
    }
  }

  await Promise.all(depreciationSchedulesPromises)
}

function computeDiscount(priceExclTax, unitPrice, quantity) {
  if (!unitPrice || !quantity) return 0
  if (priceExclTax === unitPrice * quantity) return 0
  else {
    let discount = priceExclTax / unitPrice / quantity - 1

    if (priceExclTax > unitPrice * quantity) discount = -1 * discount
    else if (discount < 0) discount = -1 * discount

    return discount
  }
}

function computePriceExclTax(unitPrice, quantity, discount = 0) {
  return unitPrice * quantity * (1 - discount)
}

function computePriceInclTax(priceExclTax, taxValue) {
  let priceInclTax = priceExclTax * (taxValue + 1)
  return priceInclTax
}

export function computeAsset(asset, patch, lists = {}) {
  let { quantity, discount, unitPrice, priceInclTax, priceExclTax, taxCode } = patch
  if ("discount" in patch && discount === undefined) discount = 0
  if ("priceExclTax" in patch && !priceExclTax) priceExclTax = 0

  const { taxes = [] } = lists
  const { value = 0 } = taxes.find(t => t.code === (taxCode || asset.taxCode)) || {}

  if (unitPrice !== undefined) {
    const { quantity = 1, discount = 0 } = asset
    const priceExclTax = computePriceExclTax(unitPrice, quantity, discount)

    patch.unitPrice = unitPrice
    patch.quantity = quantity
    patch.priceExclTax = priceExclTax
    patch.priceInclTax = computePriceInclTax(priceExclTax, value)
  }

  if (quantity !== undefined) {
    const { unitPrice = 0, discount = 0 } = asset
    const priceExclTax = computePriceExclTax(unitPrice, quantity, discount)

    patch.quantity = quantity
    patch.priceExclTax = priceExclTax
    patch.priceInclTax = computePriceInclTax(priceExclTax, value)
  }

  if (discount !== undefined) {
    const { unitPrice = 0, quantity = 1 } = asset
    const priceExclTax = computePriceExclTax(unitPrice, quantity, discount)

    patch.discount = discount
    patch.priceExclTax = priceExclTax
    patch.priceInclTax = computePriceInclTax(priceExclTax, value)
  }

  if (priceExclTax !== undefined) {
    const { unitPrice, quantity = 1 } = asset

    patch.discount = computeDiscount(priceExclTax, unitPrice ?? patch.unitPrice, quantity)
    patch.priceExclTax = priceExclTax
    patch.priceInclTax = computePriceInclTax(priceExclTax, value)
  }

  if (taxCode) {
    const priceExclTax = asset.priceExclTax || patch.priceExclTax || 0
    patch.priceInclTax = computePriceInclTax(priceExclTax, value)
  }

  if (priceInclTax !== undefined) {
    const { unitPrice, quantity = 1 } = asset
    const priceExclTax = priceInclTax / (value + 1)

    patch.discount = computeDiscount(priceExclTax, unitPrice ?? patch.unitPrice, quantity)
    patch.priceExclTax = priceExclTax
    patch.priceInclTax = priceInclTax
  }

  return {
    ...asset,
    ...patch,
  }
}

export async function applyCatalogItemToAsset(sourceCatalog, itemCode, asset, catalogs, overrideCategories = true) {
  if (!sourceCatalog || !itemCode || !asset) return

  const catalog = await runScriptAndCacheResponse(sourceCatalog)
  if (!catalog) return

  let item
  if (catalog.dynamic) {
    item = (await axios.get("/api/script/runs/" + sourceCatalog + "?search=" + itemCode)).data[0]

    asset.sourceCatalog = sourceCatalog
    if (item.auto) asset.auto = { ...asset.auto, ...item.auto }
    Object.assign(asset, item)
  } else {
    item = findOneCatalogLeafItem(catalog?.items, itemCode, catalogs)

    if (!item) return

    asset.sourceCatalog = sourceCatalog
    if (overrideCategories) asset.categories = findCatalogItemCategories(catalog, item.code)

    const {
      code,
      name,
      description,
      currency,
      finish,
      image,
      manufacturer,
      category,
      state,
      model,
      rvCode,
      serviceCode,
      taxCode,
      type,
      unitPrice,
      sourcePrice,
      rvCatalog,
      paramsCatalog,
      serviceCatalog,
      identificationNumber,
      supplierRegistration,
      usage,
      registrationDate,
      licenceNumber,
      mileage,
      modelYear,
      props,
    } = item

    if (name || description) asset.name = name || description
    if (description) asset.description = description
    if (code || model) asset.model = code || model
    if (type) asset.type = type
    if (state) asset.state = state
    if (category) asset.category = category
    if (manufacturer) asset.manufacturer = manufacturer
    if (finish) asset.finish = finish
    if (identificationNumber) asset.identificationNumber = identificationNumber
    if (supplierRegistration) asset.supplierRegistration = supplierRegistration
    if (usage) asset.usage = usage
    if (image) asset.documents = [{ type: "IMAGE", url: image, name: image }]

    if (taxCode) asset.taxCode = taxCode
    if (currency) asset.currency = currency
    if (unitPrice) asset.unitPrice = unitPrice
    if (sourcePrice) asset.sourcePrice = sourcePrice
    if (!sourcePrice && unitPrice) asset.sourcePrice = unitPrice

    if (serviceCode) asset.serviceCode = serviceCode
    if (rvCode) asset.rvCode = rvCode
    if (rvCatalog) asset.rvCatalog = rvCatalog
    if (paramsCatalog) asset.paramsCatalog = paramsCatalog
    if (serviceCatalog) asset.serviceCatalog = serviceCatalog

    if (registrationDate || licenceNumber || mileage || modelYear) {
      asset.auto = asset.auto || {}

      if (mileage) asset.auto.mileage = mileage
      if (modelYear) asset.auto.modelYear = modelYear
      if (licenceNumber) asset.auto.licenceNumber = licenceNumber
      if (registrationDate) asset.auto.registrationDate = registrationDate
    }

    if (props) {
      asset.auto = asset.auto || {}
      for (let key of Object.keys(props)) set(asset, key, props[key])
    }
  }
}

export function findCatalogLeafItems(items = [], search, autoLoadCatalogItems) {
  if (search || autoLoadCatalogItems) {
    let searchValues = []

    if (search.includes(" ")) searchValues = search.split(" ")
    else searchValues = [search]

    searchValues = searchValues.map(value => value.toUpperCase())
    return _findCatalogLeafItems(items, searchValues)
  }
  return []
}

function includes(values, search = []) {
  return (
    values.filter(value => {
      if (!value) return false
      return search.filter(s => value.toUpperCase().includes(s)).length === search.length
    }).length > 0
  )
}

function _findCatalogLeafItems(items = [], search = [], itemsFound = []) {
  for (let item of items) {
    if (item) {
      if (item.items) {
        if (Array.isArray(item.items)) _findCatalogLeafItems(item.items, search, itemsFound)
        // TODO: support subcatalogs
      } else {
        if (includes([item.code, item.name, item.description], search)) {
          itemsFound.push({
            code: item.code,
            name: item.name || item.description,
          })
        }
      }
    }
  }
  return itemsFound
}

export function findCatalogItemCategories(catalog = {}, code) {
  let categoryCodes = []
  let categoryNames = []
  const { items = [] } = catalog

  const findCatalogItem = (items, code, categoryCodes = [], categoryNames = []) => {
    for (let item of items) {
      if (item.code === code) return item

      if (Array.isArray(item.items)) {
        const found = findCatalogItem(item.items, code, categoryCodes, categoryNames)
        if (found) {
          categoryCodes.push(item.code)
          categoryNames.push(item.category)
          return found
        }
      }
    }
  }

  findCatalogItem(items, code, categoryCodes, categoryNames)
  categoryCodes.unshift(code)
  categoryNames.push(catalog.category)

  return categoryCodes.map((code, index) => ({ code, name: (categoryNames[index] || "").toUpperCase() })).reverse()
}

export function findOneCatalogLeafItem(items, code, catalogs) {
  const item = findCatalogItem(items, code, catalogs) || {}
  if (!item.items) return item
}

export function findCatalogItem(items, code, catalogs, prevCode) {
  if (items && items.subCatalogName && catalogs[items.subCatalogName]) {
    items = catalogs[items.subCatalogName].items || []
  }
  if (Array.isArray(items)) {
    for (let item of items) {
      if (item.code === code) return item
      else if (item.code === prevCode && item.allowCreate) return item.items.find(it => it.isGeneric)

      if (item.items) {
        const found = findCatalogItem(item.items, code, catalogs, prevCode)
        if (found) return found
      }
    }
  }
}

export function isValidAsset(asset) {
  return asset && (asset.name || asset.description || asset.type || asset.priceExclTax)
}

export function getLeafItemCatalogSpecifications(items = [], code, catalogs = {}) {
  const findCatalogItem = (items = [], code, specifications = []) => {
    for (let item of items) {
      if (item.code === code) return item

      if (Array.isArray(item.items)) {
        const found = findCatalogItem(item.items, code, specifications)
        if (found) {
          specifications.push(item.specifications)
          return found
        }
      } else if (item.items && item.items.subCatalogName) {
        const subCatalog = catalogs[item.items.subCatalogName]
        if (subCatalog) {
          const found = findCatalogItem(catalogs[item.items.subCatalogName].items, code, specifications)
          if (found) {
            if (subCatalog.specifications) specifications.push(subCatalog.specifications)

            specifications.push(item.specifications)
            return found
          }
        }
      }
    }
  }

  let specifications = []
  findCatalogItem(items, code, specifications)
  return specifications
    .flat()
    .filter(s => s)
    .reverse()
}

export async function executeLayout({
  prevLayout,
  asset,
  prevAsset,
  entityName: parentEntityName,
  entityType,
  inModal,
  readOnly,
  cardName,
  useAssetLayout,
  isPartExchange,
}) {
  let scriptName = "asset-layout"

  if (entityType) scriptName = `${scriptName}-${entityType}`

  const layoutScript = await runScriptLocally({ scriptName, acceptMissing: true })
  if (!layoutScript?.execute) return {}

  const taxes = await getTaxes()

  let catalog
  if (asset.sourceCatalog && !["LECTURA", "EMPREKIS", "AUTOVISTA", "AUTOBIZ", "CARTELL", "ACB"].includes(asset.sourceCatalog?.toUpperCase())) {
    catalog = await runScriptAndCacheResponse(asset.sourceCatalog)
  }

  const startTime = Date.now()
  const parameters = {
    asset,
    prevAsset,
    catalog,
    taxes,
    parentEntityName,
    inModal,
    readOnly,
    cardName,
    useAssetLayout,
    isPartExchange,
    cache: prevLayout?.cache || {},
  }

  const layout = await layoutScript.execute(parameters)

  if (debug) {
    console.log(`${scriptName} parameters`, parameters)
    console.log(`${scriptName} execution duration`, `${Date.now() - startTime} ms`)
    console.log(`${scriptName} returned`, layout)
  }

  if (layout) layout.cache = parameters.cache
  return layout
}
