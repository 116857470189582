import AdvancedSearch, { countSearchParams } from "@/_components/AdvancedSearch"
import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import CustomButton from "@/_components/CustomButton"
import DebugPopover from "@/_components/DebugPopover"
import FormInput from "@/_components/FormInput"
import FormInputExtended from "@/_components/FormInputExtended"
import SearchRowComponent from "@/_components/SearchRowComponent"
import { filterData, getSearchParamName } from "@/_services/boardUtils"
import { getLabel, getList } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { userPreferencesTypes } from "@/_services/theming"
import { addOrUpdateUserPreference, deleteUserPreference, getOptions, getUserPreferences, unpinEntity } from "@/_services/userConfiguration"
import {
  applyClasses,
  checkIfInIframe,
  customEvents,
  debug,
  downloadFileFromUrl,
  exportToExcel,
  formatCurrency,
  formatDate,
  handleAccessibleOnKeyDown,
  isNewTabOrWindowClick,
  mergeQueryParams,
  NULL,
  openDocInTab,
  searchParamToObject,
} from "@/_services/utils"
import axios from "axios"
import {
  formatBytes,
  formatMilliseconds,
  formatPercentage,
  getFieldProps,
  replaceVariables,
  unformatCurrency,
  utcParseDate,
} from "basikon-common-utils"
import cloneDeep from "lodash.clonedeep"
import get from "lodash.get"
import React, { Suspense } from "react"
import { Table as BTable, Col, FormControl, Row } from "react-bootstrap"
import { Link, withRouter } from "react-router-dom"

const Kanban = React.lazy(() => import("@/_components/Kanban"))

/**
 * @prop {boolean}  pageInUrl     Store page in URL                     Default to true.
 * @prop {boolean}  searchFromUrl Store search in URL, can also be a prefix                   Default to false
 * @prop {number}   pageSize      Number of records in a single page    Default to 16
 * @prop {boolean}  filter        Add filter input on top of table
 * @prop {function} onRowClick    Event when clicking on a row, returns full row in param.
 * @prop {object}   servicePagination Object holding pagination provided by the API returning the data
 * @prop {number}   servicePagination.page Current page
 * @prop {number}   servicePagination.pageSize Number of items in the current page
 * @prop {number}   servicePagination.nbPages Total number of pages
 * @prop {number}   servicePagination.totalItems Total number of items
 */

const defaultPageSize = 20
class Table extends React.Component {
  constructor(props) {
    super(props)

    // sideView can be an empty object
    const { sideView, pageSize, location, pageConfig } = this.props

    const userPreferences = getUserPreferences()
    const preferenceGroupId = location.pathname
    const tableConfiguration =
      userPreferences.find(({ type, groupId }) => type === userPreferencesTypes.TABLE_CONFIGURATION && groupId === preferenceGroupId) || {}

    const userColumns = tableConfiguration?.value?.columns || []
    const columns = this.getColumns()

    this.state = {
      columns,
      search: undefined,
      sorting: {},
      loadingRowActionId: false,
      loadingAdvancedSearch: false,
      showAdvancedSearch: false,
      pageNumber: null,
      isInIframe: checkIfInIframe(),
      sideView: tableConfiguration?.value?.sideView ?? pageConfig?.sideView ?? sideView ?? getOptions("sideView"),
      // Warning: pageConfig.advancedSearch is not the same as passing directly advancedSearch in the props
      // this is due to SearchRowComponent not being completely part of Table, which we must refacto one day.
      // We need that trick to set the proper default value given buy the config.
      advancedSearchInModal:
        tableConfiguration?.value?.advancedSearchInModal ?? pageConfig?.advancedSearch?.inModal ?? getOptions("advancedSearch")?.inModal,
      // it gives a better UX to manage the unpin action in-memory
      // and avoids having to trigger a soft reload which could reload kpis and so on
      unpinnedRegistrations: [],
      pageSize: tableConfiguration?.value?.pageSize ?? pageConfig?.pageSize ?? pageSize ?? defaultPageSize,
      userColumns,
      preferenceGroupId,
      userPreferences,
      showTableConfiguration: false,
      tableConfiguration,
      isSavingTableConfiguration: false,
      isResettingTableConfiguration: false,
      selectNbOfRowsToDisplay: [{ value: 5 }, { value: 10 }, { value: defaultPageSize }, { value: 25 }, { value: 50 }, { value: 100 }],
    }
  }

  componentDidMount() {
    const { advancedSearch, useSearchRowComponent } = this.props
    this.fetchSelects()

    if (advancedSearch || useSearchRowComponent) document.addEventListener("keydown", this.handleKeyboardShortcuts, false)
  }

  componentWillUnmount() {
    const { advancedSearch, useSearchRowComponent } = this.props
    if (advancedSearch || useSearchRowComponent) document.removeEventListener("keydown", this.handleKeyboardShortcuts, false)
  }

  componentDidUpdate(prevProps) {
    const {
      columns: propsColumns,
      paginationReset,
      pageInUrl,
      history,
      location: { pathname, search },
      pageConfig,
      pageSize: propsPageSize,
    } = this.props
    if (propsColumns !== prevProps.columns) {
      const columns = this.getColumns()
      this.setState({ columns }, () => this.fetchSelects())
    }

    if (prevProps.pageSize !== propsPageSize) {
      const { tableConfiguration, pageSize: statePageSize } = this.state
      const newPageSize = tableConfiguration?.value?.pageSize ?? pageConfig?.pageSize ?? propsPageSize ?? defaultPageSize
      if (newPageSize !== statePageSize) {
        this.setState({ pageSize: newPageSize })
      }
    }

    // when the data of table is changed, depending on the integration (in pages for instance)
    // we might not have access to the possibility to reload the table to reset the pagination buttons state
    // so we need this props to do so
    if (paginationReset !== prevProps.paginationReset) {
      this.setState({ pageNumber: 1 })
      if (pageInUrl) {
        const queryParams = mergeQueryParams(search, { page: 1 })
        history.replace(`${pathname}${queryParams}`)
      }
    }
  }

  getColumns() {
    const { location, columns: propsColumns, useSearchRowComponent } = this.props
    const _propsColumns = propsColumns?.map(col => (typeof col === "string" ? { name: col } : col))

    // This condition is needed to avoid props inheritance when there are Table components nested within itself.
    // This happens for instance in the entities page when using the advanced search and form inputs with entity search name.
    // The Table component is used to show the list of entities and inherits most of the props of the entities page.
    if (!useSearchRowComponent) return _propsColumns

    const userPreferences = getUserPreferences()
    const preferenceGroupId = location.pathname
    const tableConfiguration =
      userPreferences.find(({ type, groupId }) => type === userPreferencesTypes.TABLE_CONFIGURATION && groupId === preferenceGroupId) || {}
    const userColumns = tableConfiguration?.value?.columns || []
    if (userColumns.length) {
      const newColumns = []
      for (const userColumn of userColumns) {
        const userColumnInColumns = _propsColumns.find(propsColumn => propsColumn.name === userColumn.name)
        if (userColumnInColumns) newColumns.push(userColumn)
      }
      return newColumns
    }

    return _propsColumns
  }

  saveTableConfiguration = async () => {
    const { preferenceGroupId, tableConfiguration, userColumns, sideView, pageSize, advancedSearchInModal } = this.state
    const statePatch = { isSavingTableConfiguration: false }
    try {
      this.setState({ isSavingTableConfiguration: true })
      const savedTableConfiguration = await addOrUpdateUserPreference({
        _id: tableConfiguration._id,
        groupId: preferenceGroupId,
        type: userPreferencesTypes.TABLE_CONFIGURATION,
        value: { columns: userColumns, sideView, pageSize, advancedSearchInModal },
      })
      statePatch.tableConfiguration = savedTableConfiguration
    } catch (error) {
      addOops(error)
    } finally {
      this.setState(statePatch)
    }
  }

  resetTableConfiguration = async () => {
    const { sideView, pageSize, pageConfig, columns } = this.props
    const { tableConfiguration } = this.state
    const statePatch = { isResettingTableConfiguration: false }
    try {
      this.setState({ isResettingTableConfiguration: true })
      await deleteUserPreference({ _id: tableConfiguration?._id })
      this.setState({
        sideView: pageConfig?.sideView ?? sideView ?? getOptions("sideView"),
        pageSize: pageConfig?.pageSize ?? pageSize ?? defaultPageSize,
        columns,
        userColumns: [],
        advancedSearchInModal: pageConfig?.advancedSearch?.inModal ?? getOptions("advancedSearch")?.inModal,
        tableConfiguration: {},
      })
    } catch (error) {
      addOops(error)
    } finally {
      this.setState(statePatch)
    }
  }

  onSetTableConfiguration = async ({ sideView, advancedSearchInModal, pageSize, selectedColumn }) => {
    const { tableConfiguration, columns: stateColumns } = this.state
    const { value = {} } = tableConfiguration
    const statePatch = {}

    if (selectedColumn) {
      let userColumns = value.columns || []
      if (!userColumns.length) userColumns = cloneDeep(stateColumns)

      const replacedColName = Object.keys(selectedColumn)[0]
      const showColumn = selectedColumn[replacedColName]
      // some columns have dots in their name
      const colName = replacedColName.replaceAll("¤", ".")
      const tableConfColIndex = userColumns.findIndex(tableConfCol =>
        typeof tableConfCol === "string" ? tableConfCol === colName : tableConfCol.name === colName,
      )

      userColumns[tableConfColIndex].hidden = !showColumn
      userColumns[tableConfColIndex].available = true
      statePatch.userColumns = userColumns

      const clonedColumns = cloneDeep(stateColumns)
      const colIndex = clonedColumns.findIndex(column => (typeof column === "string" ? column === colName : column.name === colName))
      if (colIndex > -1) {
        clonedColumns[colIndex].hidden = !showColumn
        clonedColumns[colIndex].available = true
      } else if (showColumn) {
        const columnIndex = clonedColumns.findIndex(pageConfCol => pageConfCol.name === colName)
        if (columnIndex > -1) clonedColumns.splice(columnIndex, 0, clonedColumns[columnIndex])
      }
      statePatch.columns = clonedColumns
    }

    if (sideView !== undefined) statePatch.sideView = sideView
    if (advancedSearchInModal !== undefined) statePatch.advancedSearchInModal = advancedSearchInModal
    if (pageSize !== undefined) statePatch.pageSize = pageSize

    this.setState(statePatch)
  }

  moveColumn = async ({ colName, down }) => {
    const { columns: stateColumns } = this.state
    const columns = cloneDeep(stateColumns)

    const columnIndex = columns.findIndex(column => (typeof column === "string" ? column === colName : column.name === colName))

    const column = columns[columnIndex]
    columns.splice(columnIndex, 1)
    columns.splice(down ? columnIndex + 1 : columnIndex - 1, 0, column)

    this.setState({ columns })
  }

  handleKeyboardShortcuts = event => {
    const { advancedSearch } = this.props
    const { showAdvancedSearch } = this.state
    const { keyCode, altKey: ALT, metaKey, ctrlKey } = event
    const isMac = window.navigator.platform.match("Mac")
    const CTRL = isMac ? metaKey : ctrlKey

    const Q = keyCode === 81
    const S = keyCode === 83

    if (ALT && Q && !advancedSearch?.hidden) this.setState({ showAdvancedSearch: !showAdvancedSearch })
    if (CTRL && S) this.saveTableConfiguration()
  }

  fetchSelects() {
    const { columns = [] } = this.state
    const allSelects = (columns || [])
      .filter(col => col?.hidden !== true)
      .map(it => it?.select)
      .filter(it => it)
    allSelects.forEach(it => typeof it === "string" && getList(it, () => this.setState({ [it + "Loaded"]: true })))
  }

  changePage = pageNumber => {
    const {
      history,
      location: { pathname, search },
      pageInUrl = true,
      handleSetPage,
    } = this.props

    if (pageInUrl) {
      const queryParams = mergeQueryParams(search, { page: pageNumber })
      history.replace(`${pathname}${queryParams}`)
    }
    const callBack = () => handleSetPage && handleSetPage(pageNumber)
    this.setState({ pageNumber }, callBack)
  }

  getPages = ({ totalItems, currentPage = 1, pageSize = defaultPageSize, maxPages = 10 }) => {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize)

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1
    } else if (currentPage > totalPages) {
      currentPage = totalPages
    }

    let startPage
    let endPage
    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1
      endPage = totalPages
    } else {
      // total pages more than max so calculate start and end pages
      const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2)
      const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1
        endPage = maxPages
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1
        endPage = totalPages
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage
        endPage = currentPage + maxPagesAfterCurrentPage
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)

    // create an array of pages to ng-repeat in the pager control
    const pagesNumber = Array.from(Array(endPage + 1 - startPage).keys()).map(i => startPage + i)

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pagesNumber,
    }
  }

  includePage = (totalItems, currentPage, pageNumber) => {
    const { pageSize } = this.state
    const { servicePagination, maxPages } = this.props
    const { pagesNumber } = this.getPages({
      totalItems,
      currentPage,
      pageSize: servicePagination?.pageSize || pageSize,
      maxPages: servicePagination?.maxPages || maxPages,
    })
    return pagesNumber.includes(pageNumber)
  }

  handleHeaderClick = columnName => {
    const { sorting = {} } = this.state

    if (sorting[columnName] === true) sorting[columnName] = false
    else if (sorting[columnName] === false) sorting[columnName] = undefined
    else sorting[columnName] = true

    this.setState({ sorting })
  }

  launchSearch = search => {
    const { location, history, handleSetSearch, searchFromUrl } = this.props

    if (searchFromUrl) {
      const queryParams = mergeQueryParams(location.search, { [getSearchParamName(searchFromUrl)]: search })
      history.replace(`${location.pathname}${queryParams}`)
    } else {
      const callBack = () => handleSetSearch && handleSetSearch(search)
      this.setState({ search }, callBack)
    }
  }

  handleExportXlsx = async ({ event, tableData }) => {
    const { exportData } = this.props
    event.preventDefault()
    this.setState({ isExportingToExcel: true })

    try {
      const { columns } = this.state
      await exportToExcel({ data: tableData, columns: columns.filter(it => it.name !== "actions"), ...exportData })
    } finally {
      this.setState({ isExportingToExcel: false })
    }
  }

  // props of type function do not work if using content scripts
  // as they are compiled server-side and their results is JSON stringified
  // when sent to the client
  actionToButton = ({ action, row, rowIndex, actionIndex }) => {
    const { loadingRowActionId } = this.state
    const { history, onSetState, entity } = this.props
    let {
      bsStyle,
      pullRight,
      redirectTo,
      uri, // TODO: Remove me after release 22/11/2022 (Renamed to "url")
      url,
      method = "POST",
      successMessage,
      reloadAfterSuccess,
      redirectToAfterSuccess,
      download,
      conditionField,
      showAction = true,
      hidden,
      disabled,
      base64,
      onClick,
    } = action
    if (!url && uri) url = uri // TODO: Remove me after release 22/11/2022

    let { icon, tooltip, payload } = action

    const rowActionId = `${url}-${rowIndex}`
    const key = `${rowIndex}-${actionIndex}`
    if (loadingRowActionId === rowActionId) {
      return <i key={key} className={"icn-circle-notch icn-spin icn-xs text-black-lightest" + (pullRight ? " float-right" : "")} />
    }

    if (!icon && method == "DELETE") icon = "icn-xmark icn-xs"
    if (!tooltip && method == "DELETE") tooltip = "Delete"

    tooltip = replaceVariables(tooltip, row)
    icon = replaceVariables(icon, row)

    let _onClick
    if (base64) _onClick = () => openDocInTab({ base64: replaceVariables(base64, row) })
    if (redirectTo) _onClick = () => history.push(replaceVariables(redirectTo, row))
    if (url) {
      _onClick = async () => {
        url = replaceVariables(url, row)
        this.setState({ loadingRowActionId: rowActionId })

        if (download) await downloadFileFromUrl(url, "Downloaded", "downloading")
        else {
          try {
            let actionData
            if (method === "GET") actionData = (await axios.get(url)).data
            else if (method === "DELETE") actionData = (await axios.delete(url)).data
            else {
              if (!payload) payload = row
              else {
                // Replace variables in payload fields
                for (const key in payload) {
                  if (typeof payload[key] === "string") payload[key] = replaceVariables(payload[key], row)
                }
              }

              // Remove functions && react components
              for (const key in payload) {
                if (typeof payload[key] === "function" || React.isValidElement(payload[key])) delete payload[key]
                if (Array.isArray(payload[key])) {
                  for (const item of payload[key]) {
                    if (typeof item === "function" || React.isValidElement(item)) {
                      delete payload[key]
                      break
                    }
                  }
                }
              }

              if (method === "PATCH") actionData = (await axios.patch(url, payload)).data
              else if (method === "PUT") actionData = (await axios.put(url, payload)).data
              else actionData = (await axios.post(url, payload)).data
            }

            addNotification(successMessage || "Event executed successfully")
            if (reloadAfterSuccess) setTimeout(() => window.location.reload(), 500)
            if (redirectToAfterSuccess) history.push(replaceVariables(redirectToAfterSuccess, actionData))
          } catch (error) {
            addOops(error)
          }
        }
        this.setState({ loadingRowActionId: null })
      }
    }
    if (typeof onClick === "function") {
      _onClick = async () => {
        const actionPatch = await onClick({ pageState: cloneDeep(entity), row, rowIndex })
        if (actionPatch) onSetState(actionPatch)
      }
    }

    const showActionButton = conditionField ? row[conditionField] : typeof showAction === "function" ? showAction({ row, rowIndex }) : showAction
    if (showActionButton && !hidden) {
      return (
        <ButtonWithTooltip
          key={key}
          className={icon}
          disabled={typeof disabled === "function" ? disabled({ row, rowIndex }) : disabled}
          {...{ bsStyle, pullRight, tooltip, onClick: _onClick }}
        />
      )
    }
  }

  // the row change can be either handled manually by the configuration like in Page
  // or delegated back to the app when the function onRowChange returns a patch
  handleRowChange = async (row, rowIndex, patch) => {
    const { pageNumber, pageSize } = this.state
    const { onRowChange, onSetState, entity, execComputations, data } = this.props
    const indexRow = pageNumber > 1 ? (pageNumber - 1) * pageSize + rowIndex : rowIndex

    if (typeof onRowChange !== "function") return
    const rowPatch = await onRowChange({ pageState: cloneDeep(entity), row, data, rowIndex: indexRow, patch })
    if (rowPatch) onSetState(rowPatch, execComputations)
  }

  getPaginationButtons = ({ nbPages, pageNumber, totalItems, rowsCount, paginationParams, getEntities, loading, typeFieldBreakdownValue }) => {
    const paginationButtons = []
    const hasMoreThanOnePage = nbPages > 1

    // When using breakdown we force each table to have one page only,
    // thus the pagination are not shown in this case.
    if (hasMoreThanOnePage) {
      if (nbPages > 10) {
        const firstDisabled = pageNumber === 1
        paginationButtons.push(
          <div
            key="first"
            aria-describedby={loc("First page")}
            tabIndex={firstDisabled ? "-1" : "0"}
            className={"pagination-btn " + (firstDisabled ? " disabled pointer-events-none" : "")}
            onKeyDown={event => {
              handleAccessibleOnKeyDown({ event, fn: () => !firstDisabled && this.changePage(1) })
            }}
            onClick={() => !firstDisabled && this.changePage(1)}
          >
            <i className="icn-angle-double-left icn-xxs" />
          </div>,
        )
      }

      const prevDisabled = pageNumber <= 1
      paginationButtons.push(
        <div
          key="prev"
          aria-describedby={loc("Previous page")}
          tabIndex={prevDisabled ? "-1" : "0"}
          className={"pagination-btn " + (prevDisabled ? " disabled pointer-events-none" : "")}
          onKeyDown={event => {
            handleAccessibleOnKeyDown({ event, fn: () => !prevDisabled && this.changePage(pageNumber - 1) })
          }}
          onClick={() => !prevDisabled && this.changePage(pageNumber - 1)}
        >
          <i className="icn-chevron-left icn-xxs" />
        </div>,
      )

      for (let i = 1; i <= nbPages; i++) {
        if (this.includePage(totalItems, pageNumber + 1, i)) {
          const isActiveButton = i === pageNumber
          paginationButtons.push(
            <div
              tabIndex={isActiveButton ? "-1" : "0"}
              className={"pagination-btn" + (isActiveButton ? " active" : "")}
              key={i}
              aria-describedby={`${loc("Page")} ${i}`}
              onKeyDown={event => {
                handleAccessibleOnKeyDown({ event, fn: () => this.changePage(i) })
              }}
              onClick={() => this.changePage(i)}
            >
              {i}
            </div>,
          )
        }
      }

      const nextDisabled = pageNumber >= nbPages
      paginationButtons.push(
        <div
          key="next"
          aria-describedby={loc("Next page")}
          tabIndex={nextDisabled ? "-1" : "0"}
          className={"pagination-btn " + (nextDisabled ? " disabled pointer-events-none" : "")}
          onKeyDown={event => {
            handleAccessibleOnKeyDown({ event, fn: () => !nextDisabled && this.changePage(pageNumber + 1) })
          }}
          onClick={() => !nextDisabled && this.changePage(pageNumber + 1)}
        >
          <i className="icn-chevron-right icn-xxs" />
        </div>,
      )

      if (nbPages > 10) {
        const lastDisabled = pageNumber === nbPages
        paginationButtons.push(
          <div
            key="last"
            aria-describedby={loc("Last page")}
            tabIndex={lastDisabled ? "-1" : "0"}
            className={"pagination-btn " + (lastDisabled ? " disabled pointer-events-none" : "")}
            onKeyDown={event => {
              handleAccessibleOnKeyDown({ event, fn: () => !lastDisabled && this.changePage(nbPages) })
            }}
            onClick={() => !lastDisabled && this.changePage(nbPages)}
          >
            <i className="icn-angle-double-right icn-xxs" />
          </div>,
        )
      }
    }

    if (rowsCount > 0) {
      paginationButtons.push(
        <div
          key="rows-count"
          className={
            "flex flex-dir-column justify-content-center font-1 ml-theme mr-theme text-black-lightest" + (hasMoreThanOnePage ? "" : " text-center")
          }
        >
          <div>{`${rowsCount}${
            paginationParams ? `/${paginationParams.continues ? (typeFieldBreakdownValue && totalItems ? totalItems : "*") : rowsCount} ` : ""
          } ${loc`rows`}`}</div>

          {paginationParams?.continues && (
            <div
              tabIndex="0"
              className={"link flex align-items-center " + (loading ? "text-black-lightest pointer-events-none" : "")}
              onKeyDown={event => {
                handleAccessibleOnKeyDown({
                  event,
                  fn: () => getEntities && getEntities({ nextPagination: paginationParams, typeFieldBreakdownValue }),
                })
              }}
              onClick={() => getEntities && getEntities({ nextPagination: paginationParams, typeFieldBreakdownValue })}
            >
              <i className={"icn-xxs " + (loading ? "icn-circle-notch icn-spin" : "icn-plus-light")} />
              {loc("Load more")}
            </div>
          )}
        </div>,
      )
    }
    return paginationButtons
  }

  getDataRows = ({
    totalItems,
    hasWithTotalRow,
    tableData,
    filteredPinnedData,
    nbOfPinnedData,
    displayedColumns,
    nbOfColumns,
    actions: columnActions,
    modelPath,
    showHeaders,
    modelPathNested,
    pageNumber,
    showIndexColumn,
    pageSize,
    locale,
    selectedEntities,
    selectedEntitiesDisplayType,
    onRowClick,
    onRowChange,
    hasTypeFieldBreakdown,
  }) => {
    const { isInIframe, sideView } = this.state

    let shouldOverflowX = true
    const dataRowsTypeFieldBreakdown = {}
    const dataRows = tableData.map((dataRow = {}, rowIndex) => {
      const { registration, content, className = "", tdClassName = "", _arrayIndex } = dataRow

      if (content) {
        return (
          <tr key={rowIndex} className={className}>
            <td colSpan={displayedColumns.length} className={tdClassName}>
              {content}
            </td>
          </tr>
        )
      }

      // If columns do not include actions, fallback to row-specific actions.
      const actions = columnActions || dataRow.actions

      const isPinnedData = nbOfPinnedData
        ? filteredPinnedData.find(filteredPinnedItem => filteredPinnedItem.registration === registration)
        : undefined

      const dataColumns = displayedColumns.map((column, colIndex) => {
        let {
          name: columnName,
          linkTo,
          openLinkInNewTab,
          sideView: colSideView,
          download,
          select,
          multiple,
          richValuesList,
          badge,
          type,
          currency,
          colorSign,
          timeFormat,
          minimumFractionDigits,
          maximumFractionDigits,
          debounce,
          linkClassName,
        } = column

        const colSpan = colIndex === nbOfColumns - 1 && nbOfPinnedData && !isPinnedData ? 2 : undefined

        if (columnName === "actions" && Array.isArray(actions)) {
          return (
            <td
              key={colIndex}
              data-model-field-path={
                (modelPath ? `${modelPath}[${_arrayIndex ?? rowIndex}].` : "") + (modelPathNested ? `${modelPathNested}.` : "") + columnName
              }
              className={!showHeaders ? column.className : undefined}
            >
              {actions.map((action, actionIndex) => this.actionToButton({ action, row: dataRow, rowIndex, actionIndex })).filter(action => action)}
            </td>
          )
        }

        let value
        let cellValue // mainly for CSS targeting in pages
        if (showIndexColumn && columnName === "index") {
          value = rowIndex + 1 + (pageNumber - 1) * pageSize
          cellValue = value
        } else {
          value = dataRow[columnName]
          cellValue = value
        }

        if (!value && columnName?.indexOf(".") >= 0) {
          value = get(dataRow, columnName)
          cellValue = value
        }

        // By default the table is allowed to overflow along the x axis.
        // However this creates a problem for dropdown and select menus that go beyond the table frame bottom border as it cuts them.
        // To avoid this undesirable effect but still benefit from the x overflow when possible, we try to detect when
        // the table contains these elements.
        if (
          (value?.props?.select && !value?.props?.badge) ||
          Array.isArray(value?.content?.props?.children) ||
          Array.isArray(value?.props?.children)
        ) {
          shouldOverflowX = false
        }

        currency = replaceVariables(currency, dataRow)
        if (value?.hasOwnProperty("content")) {
          let { content, className = "", colSpan: valueColSpan } = value || {}
          if (type === "currency") {
            content = typeof content === "number" ? formatCurrency(content, locale, currency) : content
          } else if (type === "percentage") {
            content = typeof content === "number" ? formatPercentage(content, locale) : content
          } else if (type === "bytes") {
            content = typeof content === "number" ? formatBytes(content) : content
          } else if (type === "milliseconds") {
            content = typeof content === "number" ? formatMilliseconds(content) : content
          } else if (type === "object") {
            // Warning: this is special case where an object has the property content,
            // which collides with the content property we expect from content scripts.
            content = JSON.stringify(content, null, 2)
          }
          return (
            <td
              key={colIndex}
              className={className ? className : undefined}
              colSpan={valueColSpan || colSpan}
              data-cell-value={typeof content === "object" ? "" : content}
            >
              {content}
            </td>
          )
        } else {
          if (type === "bytes") {
            value = formatBytes(value)
            cellValue = value
          } else if (type === "milliseconds") {
            value = formatMilliseconds(value)
            cellValue = value
          } else if (type === "object") {
            if (typeof value === "object" || Array.isArray(value)) {
              value = (
                <pre className="max-h-500px">
                  <code>{JSON.stringify(value, null, 2)}</code>
                </pre>
              )
              // otherwise it will appear [object Object]
              cellValue = undefined
            } else {
              cellValue = value
            }
          } else if (React.isValidElement(value)) {
            cellValue = value.props?.value ?? value.props?.children
            if (value.props?.type === "date") cellValue = formatDate(cellValue, locale)
          } else if (type || select || column.formInputProps?.select || column.formInputProps?.searchEntityName) {
            if (column._selectedEntitiesDisplay) {
              cellValue = value
              value = <FormInput inArray type={type} option={true} value={selectedEntities.has(dataRow.id)} onSetState={() => onRowClick(dataRow)} />
            } else {
              // Apply props only when they are defined
              // To avoid that select={undefined} overrides the select of the <FormInputExtended searchEntityName="..."/>
              let formInputProps = {}
              if (type) formInputProps.type = type
              if (value || value === 0 || value === false) formInputProps.value = value
              if (badge) formInputProps.badge = badge
              if (select) formInputProps.select = select
              if (multiple) formInputProps.multiple = multiple
              if (debounce) formInputProps.debounce = debounce
              if (richValuesList) formInputProps.richValuesList = richValuesList
              if (currency) formInputProps.currency = currency
              if (colorSign) formInputProps.colorSign = colorSign
              if (timeFormat) formInputProps.timeFormat = timeFormat
              if (minimumFractionDigits) formInputProps.minimumFractionDigits = minimumFractionDigits
              if (maximumFractionDigits) formInputProps.maximumFractionDigits = maximumFractionDigits

              const configProps = getFieldProps(dataRow, columnName) || {}
              const allFormInputProps = {
                ...formInputProps,
                ...column.formInputProps,
                ...configProps,
              }
              if (!allFormInputProps.modelPath) {
                allFormInputProps.modelPath = modelPath ? `${modelPath}[${rowIndex}]` : ""
              }

              const isLastRow = rowIndex === totalItems
              const isTotalRow = hasWithTotalRow && isLastRow
              cellValue = value ? (value instanceof Date ? formatDate(value, locale) : value) : ""
              value = (
                <FormInputExtended
                  inArray
                  obj={dataRow}
                  field={columnName}
                  readOnly={column.readOnly || !onRowChange || isTotalRow}
                  onSetState={patch => this.handleRowChange(dataRow, rowIndex, patch)}
                  {...allFormInputProps}
                />
              )
            }
          } else if (value instanceof Date) {
            value = formatDate(value, locale)
            cellValue = value
          }

          if (linkTo) {
            const to = replaceVariables(linkTo, dataRow)
            cellValue = value
            if (!to?.includes("{")) {
              if (download) {
                value = (
                  <a className={`c-pointer ${linkClassName || ""}`} onClick={() => downloadFileFromUrl(to, "Downloaded", "downloading")}>
                    {value}
                  </a>
                )
              } else if (to?.startsWith("http")) {
                value = (
                  <a href={to} className={linkClassName} target="_blank" rel="noopener noreferrer">
                    {value}
                  </a>
                )
              } else if ((sideView || colSideView) && !isInIframe) {
                value = (
                  <a
                    href={to}
                    className={linkClassName}
                    onClick={event => {
                      if (isNewTabOrWindowClick(event)) return
                      event.preventDefault()
                      event.stopPropagation()
                      window.dispatchEvent(new CustomEvent(customEvents.sideView.setUrl, { detail: { url: to } }))
                    }}
                  >
                    {value}
                  </a>
                )
              } else {
                value = (
                  <Link className={linkClassName} to={to} target={openLinkInNewTab ? "_blank" : undefined}>
                    {value}
                  </Link>
                )
              }
            }
          }

          return (
            <td
              colSpan={colSpan}
              key={colIndex}
              data-model-field-path={
                (modelPath ? `${modelPath}[${_arrayIndex ?? rowIndex}].` : "") + (modelPathNested ? `${modelPathNested}.` : "") + column.name
              }
              className={!showHeaders ? column.className : undefined}
              data-cell-value={typeof cellValue === "object" ? "" : cellValue}
            >
              {value}
            </td>
          )
        }
      })

      // Each time a character is entered, the React key changes so we cannot enter 2 characters to follow if the column name is "id"!
      // Passing "id" means that we want the key to be stable, to avoid redrawing and relaunching maybe requests from the redrawn rows
      // (like when using FormInputExtended with searchEntityName), so don't concat it with an unstable element like rowIndex again.
      const key = dataRow.id ?? rowIndex

      const tableRow = (
        <tr
          key={key}
          className={applyClasses({
            [className]: true,
            "selected-row": !selectedEntitiesDisplayType && selectedEntities?.has(dataRow.id || dataRow.registration),
          })}
          tabIndex={onRowClick ? "0" : "-1"}
          onKeyDown={event => {
            if (!onRowClick) return
            handleAccessibleOnKeyDown({ event, fn: () => onRowClick(dataRow) })
          }}
          onClick={
            onRowClick
              ? event => {
                  // bypass if clicking on a link, input or label or chevron button (mostly checkboxes) to avoid selection of a row when ctrl+click on a link
                  const eventTagName = event.target?.tagName?.toLowerCase()
                  if (!["a", "input", "label", "i"].includes(eventTagName)) {
                    // prevent triggering double clicks on checkbox with multi selection
                    event.preventDefault()
                    onRowClick(dataRow)
                  }
                }
              : undefined
          }
        >
          {dataColumns}
          {isPinnedData && (
            <td className="pd-0 text-right text-black-lightest">
              <ButtonWithTooltip
                bsStyle="default"
                className="icn-thumbtack icn-xs"
                tooltip={"Unpin"}
                onClick={event => this.unpinRow({ event, dataRow })}
              />
            </td>
          )}
        </tr>
      )

      if (hasTypeFieldBreakdown) {
        // server-side data without type defined are returned under the NULL type key
        // whereas here their value is undefined, so we have to turn that to NULL explicitly
        dataRowsTypeFieldBreakdown[dataRow.type || NULL] ??= []
        dataRowsTypeFieldBreakdown[dataRow.type || NULL].push(tableRow)
      }

      return tableRow
    })

    return { dataRows, shouldOverflowX, dataRowsTypeFieldBreakdown }
  }

  unpinRow = async ({ event, dataRow }) => {
    const { unpinnedRegistrations } = this.state
    event.stopPropagation()
    const { entityName } = this.props
    const { registration } = dataRow
    if (!entityName || !registration) return
    await unpinEntity({ entityName, registration })
    this.setState({ unpinnedRegistrations: [...unpinnedRegistrations, registration] })
  }

  getColumnsHeaders = ({ showHeaders, columns, modelPath, sorting, onSetColumns }) => {
    return showHeaders
      ? columns
          .filter(col => col && col?.hidden !== true)
          .map((column, index) => {
            let { title, className = "", name, sortable = true } = column
            let cellValue = ""
            if (typeof title === "string") {
              cellValue = title
              title = loc(title)
            }

            if (Number.isInteger(column.width)) className += ` w-${column.width}`

            // add d&d props only if "onSetColumns" is defined
            const dndProps = onSetColumns && {
              draggable: true,
              style: { cursor: this.dnd ? "grabbing" : "grab" },
              onDragStart: () => {
                this.dnd = { context: "HEADER", sourceColumnIndex: index }
              },
              onDragStop: () => {
                this.dnd = null
              },
              onDragEnter: () => {
                if (this.dnd) {
                  this.dnd.targetColumnIndex = index
                }
              },
              onDragOver: e => {
                if (this.dnd) {
                  e.stopPropagation()
                  if (this.dnd.sourceColumnIndex !== index && this.dnd.sourceColumnIndex !== index - 1) {
                    e.preventDefault()
                  }
                }
              },
              onDrop: () => {
                if (this.dnd?.targetColumnIndex >= 0) {
                  const { sourceColumnIndex, targetColumnIndex } = this.dnd
                  const newColumns = [...columns]
                  if (sourceColumnIndex < targetColumnIndex) {
                    newColumns.splice(targetColumnIndex, 0, columns[sourceColumnIndex])
                    newColumns.splice(this.dnd.sourceColumnIndex, 1)
                  } else {
                    newColumns.splice(this.dnd.sourceColumnIndex, 1)
                    newColumns.splice(targetColumnIndex, 0, columns[sourceColumnIndex])
                  }
                  onSetColumns(newColumns)
                }
              },
            }

            return (
              <th
                key={index}
                className={`${className} c-pointer relative white-space-nowrap`}
                onClick={sortable ? () => this.handleHeaderClick(name) : undefined}
                data-cell-value={typeof cellValue === "object" ? "" : cellValue}
                {...dndProps}
              >
                {title}
                {title && !["action", "actions"].includes(name) && debug && (
                  <DebugPopover
                    debug={debug}
                    select={column.select}
                    modelFieldPath={modelPath ? modelPath + "[]." + name : name}
                    spanClassName="table-header-debug"
                  />
                )}
                {sorting[name] === true && <i className="icn-sort-up icn-xxs inline-flex" />}
                {sorting[name] === false && <i className="icn-sort-down icn-xxs inline-flex" />}
              </th>
            )
          })
      : []
  }

  getTable({ bordered, id, bsClass, onRowClick, hover, showHeaders, columnHeaders, hasDataRows, dataRows, loading, nbOfColumns }) {
    return (
      <BTable
        bordered={bordered}
        id={id}
        data-test={this.props["data-test"] || "table"}
        className={applyClasses({ "c-pointer": onRowClick })}
        bsClass={bsClass}
        data-hover-background-change={hover}
      >
        {showHeaders && (
          <thead>
            <tr>{columnHeaders}</tr>
          </thead>
        )}
        <tbody>
          {hasDataRows ? (
            dataRows
          ) : loading ? (
            <tr className="text-center">
              <td colSpan={nbOfColumns} className="overflow-hidden">
                <i className="icn-circle-notch icn-spin icn-sm text-gray" />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={nbOfColumns}>
                <span className="flex align-items-center min-h-24px">{loc`No data`}</span>
              </td>
            </tr>
          )}
        </tbody>
      </BTable>
    )
  }

  getTables({
    bordered,
    id,
    bsClass,
    onRowClick,
    hover,
    showHeaders,
    columnHeaders,
    hasDataRows: propsHasDataRows,
    dataRows: propsDataRows,
    loading,
    nbOfColumns,
    dataRowsTypeFieldBreakdown,
    typeFieldBreakdownKeys,
    hasTypeFieldBreakdown,
    breakdown,
    hasSearchResultsOnHiddenFields,
    nbOfTypeFieldBreakdownKeys,
    nbPages,
    pageNumber,
    totalItems,
    rowsCount,
    paginationParams,
    getEntities,
  }) {
    const { showRowsCount } = this.props

    if (hasTypeFieldBreakdown) {
      const hasOneTypeOnly = nbOfTypeFieldBreakdownKeys === 1

      return (
        <Row>
          {typeFieldBreakdownKeys.map(typeFieldBreakdownValue => {
            const dataRows = dataRowsTypeFieldBreakdown[typeFieldBreakdownValue]
            const hasDataRows = !!dataRows?.length
            const nbOfRows = dataRows?.length
            const typeBreakdown = breakdown.type[typeFieldBreakdownValue]
            const paginationButtons = this.getPaginationButtons({
              nbPages: 1,
              pageNumber: 1,
              totalItems: typeBreakdown.count,
              rowsCount: showRowsCount ? nbOfRows : undefined,
              paginationParams: typeBreakdown,
              getEntities,
              loading,
              typeFieldBreakdownValue,
            })

            return (
              <Col key={typeFieldBreakdownValue} xs={12} md={hasOneTypeOnly ? 12 : 6} className="table-wrapper">
                <div className="overflow-x-auto">
                  <h5 className="font-1-5 font-weight-bold mb-0 mt-theme pdt-3 mt-0 mb-5px border-1px bt-solid border-gray-lighter">
                    {typeBreakdown.label}
                  </h5>

                  {this.getTable({
                    bordered,
                    id,
                    bsClass,
                    onRowClick,
                    hover,
                    showHeaders,
                    columnHeaders,
                    hasDataRows,
                    dataRows,
                    loading,
                    nbOfColumns,
                  })}
                </div>

                {paginationButtons?.length > 0 && (
                  <div className="pagination" data-test="table-pagination">
                    {paginationButtons}
                  </div>
                )}

                {!loading && hasDataRows && hasSearchResultsOnHiddenFields && (
                  <div className="flex-center font-1-2 text-gray-darkest">
                    <i className="icn-info-circle-light icn-xs" />
                    {loc("The search has found results on fields not displayed")}
                  </div>
                )}
              </Col>
            )
          })}
        </Row>
      )
    }

    const paginationButtons = this.getPaginationButtons({ nbPages, pageNumber, totalItems, rowsCount, paginationParams, getEntities, loading })

    return (
      <>
        {/* We need to wrap in Row + Col to avoid z-index issues with dropdown from the advanced search component */}
        <Row>
          <Col xs={12} className="table-wrapper">
            {this.getTable({
              bordered,
              id,
              bsClass,
              onRowClick,
              hover,
              showHeaders,
              columnHeaders,
              hasDataRows: propsHasDataRows,
              dataRows: propsDataRows,
              loading,
              nbOfColumns,
            })}
          </Col>
        </Row>

        {paginationButtons?.length > 0 && (
          <div className="pagination" data-test="table-pagination">
            {paginationButtons}
          </div>
        )}

        {!loading && propsHasDataRows && hasSearchResultsOnHiddenFields && (
          <div className="flex-center font-1-2 text-gray-darkest">
            <i className="icn-info-circle-light icn-xs" />
            {loc("The search has found results on fields not displayed")}
          </div>
        )}
      </>
    )
  }

  render() {
    let {
      search = "",
      sorting = {},
      isExportingToExcel,
      showAdvancedSearch,
      loadingAdvancedSearch,
      editMode = true,
      unpinnedRegistrations,
      pageSize,
      sideView,
      selectNbOfRowsToDisplay,
      showTableConfiguration,
      advancedSearchInModal = true,
      userColumns,
      columns = [],
      nbOfConfiguredOrDefaultColumns,
      isSavingTableConfiguration,
      isResettingTableConfiguration,
      pageNumber: statePageNumber,
    } = this.state

    const {
      id,
      location,
      actions,
      filter,
      useSearchRowComponent,
      postFilter,
      exportData,
      handleRefresh,
      onFilterEnter,
      onRowClick,
      onRowChange,
      pageInUrl = true,
      searchFromUrl = false,
      loading,
      className = "",
      bsClass = "table",
      selectedEntities,
      selectedEntitiesDisplayType, // default is undefined, meaning clicking on a row highlights it ; other values are checkbox and radio
      additionalPageRows = 0,
      servicePagination,
      serviceSearch,
      overflowX,
      showIndexColumn,
      showRowsCount,
      advancedSearch,
      encodeSearchValues,
      modelPath = "",
      modelPathNested = "", // sometimes the model is like assets[].asset.description but columns provided don't include the intermediate key (here asset)
      alwaysShowFilter = false,
      bordered,
      hover,
      filterData: propsFilterData,
      handleSetSearch,
      onSetColumns,
      data = [],
      showHeaders: propsShowHeaders,
      pinnedData = [],
      hasSearchResultsOnHiddenFields,
      entityName,
      searchFields,
      getEntities,
      onDragStart,
      onDrop,
      isKanbanView,
      pageConfig,
      exportDataFileName,
      showTableConfigurationBtn,
      onToggleButtonClick,
      toggleButtonClassName,
      paginationParams,
      breakdown,
    } = this.props
    let { rowsCount = 0 } = this.props

    if (showIndexColumn && columns[0] && columns[0].name !== "index")
      columns.unshift({ title: "N°", name: "index", sortable: false, className: "w-5" })

    if (Array.isArray(actions) && !columns.find(c => c?.name === "actions")) columns.push({ title: <>&#8205;</>, name: "actions" })

    // we put the column at the beggining because it is the standard UX of radios and checkboxes
    if (onRowClick && selectedEntities && selectedEntitiesDisplayType)
      columns = [{ title: "Selected", name: "isSelected", type: selectedEntitiesDisplayType, _selectedEntitiesDisplay: true }, ...columns]

    if (!columns) columns = []

    // watch out: here we have to use this syntax instead of col?.hidden to make sure to filter out empty cols
    const displayedColumns = columns.filter(col => col && col.hidden !== true)
    const nbOfColumns = displayedColumns.length
    const locale = getLocale()

    let nbPages = 0
    let totalItems = 0

    let tableData
    const filteredPinnedData = pinnedData.filter(({ registration }) => !unpinnedRegistrations.includes(registration))
    const nbOfPinnedData = filteredPinnedData?.length
    if (nbOfPinnedData) {
      tableData = [
        ...filteredPinnedData,
        ...data.filter(item => (item ? !filteredPinnedData.find(pinnedItem => pinnedItem.registration === item.registration) : undefined)),
      ]
    } else {
      // Remove nullish values
      tableData = data.filter(item => item)
    }

    const queryParams = searchParamToObject(location.search)
    search = searchFromUrl ? queryParams[getSearchParamName(searchFromUrl)] : search
    if (search || propsFilterData) tableData = propsFilterData ? propsFilterData(columns, tableData, search) : filterData(columns, tableData, search)

    // the pagination is provided by a service, typically an API
    if (servicePagination) {
      nbPages = servicePagination.nbPages
      totalItems = servicePagination.totalItems
    } else {
      nbPages = Math.ceil(tableData.length / pageSize)
      totalItems = tableData.length
    }

    let index = 0
    tableData.forEach(it => {
      if (it.content) it._linkedIndex = index ? index - 1 : index
      else it._index = index++
    })

    sortTableData(tableData, sorting)

    if (showRowsCount) rowsCount = tableData.length

    let pageNumber = pageInUrl ? queryParams.page : statePageNumber
    pageNumber = pageNumber && pageNumber <= nbPages ? Number(pageNumber) : 1

    const showHeaders = propsShowHeaders === undefined ? columns.filter(col => col?.hidden !== true && col?.title).length > 0 : propsShowHeaders
    const columnHeaders = this.getColumnsHeaders({ columns, modelPath, showHeaders, sorting, editMode, onSetColumns })

    const typeFieldBreakdownKeys = Object.keys(breakdown?.type || {})
    const nbOfTypeFieldBreakdownKeys = typeFieldBreakdownKeys.length
    const hasTypeFieldBreakdown = breakdown && nbOfTypeFieldBreakdownKeys
    // Save the initial value of tableData for export
    const exportedTableData = tableData
    // Reset page to 1 if page is too large
    if (!servicePagination && !hasTypeFieldBreakdown) {
      if (tableData.slice((pageNumber - 1) * pageSize, pageNumber * pageSize).length === 0 && pageNumber > 1) {
        pageNumber = Math.floor(tableData.length / pageSize) + 1
      }
      tableData = tableData.slice((pageNumber - 1) * pageSize, pageNumber * pageSize + additionalPageRows)
    }

    const columnsWithTotals = displayedColumns.filter(col => col?.withTotal)
    const hasWithTotalRow = !!columnsWithTotals.length
    if (hasWithTotalRow) {
      const totalLineObj = {}
      for (const { name, withTotal } of columnsWithTotals) {
        totalLineObj[name] = totalLineObj[name] || {
          className: `font-weight-bold ${withTotal?.className || ""}`,
          content: withTotal?.value || 0,
        }

        if (typeof withTotal?.value === "number") continue

        for (const dataRow of tableData) {
          let value = get(dataRow, name)
          if (value && typeof value === "object" && "content" in value) value = value.content
          if (React.isValidElement(value)) {
            const { field, obj, value: propsValue } = value.props
            value = propsValue || get(obj, field)
          }
          totalLineObj[name].content += Number(value) || 0
        }
      }

      for (const name in totalLineObj) {
        const column = columnsWithTotals.find(column => column.name === name)
        if (column?.withTotal?.highlightSignColor) {
          if (totalLineObj[name].content < 0) totalLineObj[name].className += ` text-danger`
          else if (totalLineObj[name].content > 0) totalLineObj[name].className += ` text-success`
        }
      }

      tableData = [...tableData, totalLineObj]
    }

    const { dataRows, dataRowsTypeFieldBreakdown, shouldOverflowX } = this.getDataRows({
      totalItems,
      hasWithTotalRow,
      actions,
      tableData,
      filteredPinnedData,
      nbOfPinnedData,
      displayedColumns,
      nbOfColumns,
      locale,
      modelPath,
      modelPathNested,
      onRowChange,
      onRowClick,
      pageNumber,
      pageSize,
      selectedEntities,
      selectedEntitiesDisplayType,
      showHeaders,
      showIndexColumn,
      hasTypeFieldBreakdown,
    })

    const hasDataRows = dataRows.length > 0

    let searchParamsNb
    if (advancedSearch) searchParamsNb = countSearchParams(advancedSearch.form || queryParams, advancedSearch.fields || advancedSearch.entity)

    return (
      <div className={"table-component " + className} data-overflow-x={overflowX ?? shouldOverflowX}>
        {(!loading || alwaysShowFilter) && filter && !useSearchRowComponent && (
          <Row className="mb-theme pseudo-select-control">
            <Col xs={12} sm={6} md={5} lg={3}>
              <div className="relative">
                <div className="search-input-icon pdr-0 m-0 min-w-0 absolute z-10 bg-white top-1px">
                  <ButtonWithTooltip
                    bsStyle="default"
                    className="icn-search icn-sm"
                    btnClassName="pd-0 m-0 text-gray-darker min-w-0 inline-flex-center min-w-24px min-h-24px"
                    tooltip={"Search"}
                    tabIndex="-1"
                  />
                </div>

                <FormControl
                  type="text"
                  bsClass="form-control"
                  placeholder={loc(serviceSearch?.phLabel ?? "Search...")}
                  value={search}
                  onChange={e => this.launchSearch(e.target.value)}
                  onKeyDown={event => {
                    if (event.key === "Enter") {
                      event.preventDefault()
                      if (onFilterEnter) onFilterEnter(event.target.value)
                    }
                  }}
                />
              </div>
            </Col>

            {postFilter}

            {advancedSearch && (
              <ButtonWithTooltip
                bsStyle="default"
                className={`icn-search-filter ${showAdvancedSearch ? "icn-rotate-180 text-info" : ""}`}
                data-test="search-entities-btn"
                btnClassName="mr-0 flex-center"
                onClick={() => this.setState({ showAdvancedSearch: !showAdvancedSearch })}
                disabled={loading}
                tooltip={"Toggle search filters (ALT + Q)"}
                statusIndicator={searchParamsNb ? "info" : ""}
              />
            )}

            {exportData && (
              <ButtonWithTooltip
                bsStyle="default"
                className={`${isExportingToExcel ? "icn-circle-notch icn-spin" : "icn-excel-download"}`}
                onClick={event => this.handleExportXlsx({ event, tableData: exportedTableData })}
                disabled={isExportingToExcel}
                btnClassName="mr-0 flex-center"
                tooltip={"Download data as Excel file"}
              />
            )}

            {handleRefresh && (
              <ButtonWithTooltip
                bsStyle="default"
                disabled={loading}
                className={`icn-reload ${loading ? "icn-spin" : ""}`}
                onClick={handleRefresh}
                btnClassName="mr-0 flex-center"
                tooltip={"Reload data"}
              />
            )}
          </Row>
        )}

        {useSearchRowComponent && !filter && (
          // This component is redundant with the other buttons already here and AdvancedSearch.
          // It exists for legacy reasons and should one day be merged.
          <SearchRowComponent
            onToggleButtonClick={onToggleButtonClick}
            toggleButtonClassName={toggleButtonClassName}
            loading={loading}
            entityName={entityName}
            searchFields={searchFields}
            sortableFields={columns}
            getEntities={getEntities}
            advancedSearch={pageConfig?.advancedSearch}
            hideAutocomplete={pageConfig?.hideAutocomplete}
            showTableConfigurationBtn={showTableConfigurationBtn}
            toggleTableConfiguration={({ show }) => this.setState({ showTableConfiguration: show })}
            advancedSearchInModal={advancedSearchInModal}
            exportData={exportDataFileName ? { data, columns, fileName: exportDataFileName, ...(pageConfig?.exportData || {}) } : undefined}
          />
        )}

        {advancedSearch && (
          <Row>
            <Col xs={12}>
              <AdvancedSearch
                show={showAdvancedSearch}
                loading={loadingAdvancedSearch}
                advancedSearch={advancedSearch}
                encodeValues={encodeSearchValues}
                close={() => this.setState({ showAdvancedSearch: false })}
                searchFunction={args => {
                  if (!handleSetSearch) return
                  // for compat we are forced to split the search params from the rest of returned properties
                  // which is unlike the searchFunction called in SearchRowComponent
                  const { params, ...rest } = args
                  handleSetSearch(params, rest)
                }}
              />
            </Col>
          </Row>
        )}

        {showTableConfiguration && (
          <Row>
            <Col xs={12}>
              <div className="card no-card mb-theme bb-solid border-1px border-gray">
                <div className="title mb-theme pdt-theme bt-solid border-1px border-gray">
                  {loc("Table configuration")}

                  {/* This div is need to responsiveness, so that buttons are moved together */}
                  <div className="pull-right">
                    <CustomButton
                      bsSize="sm"
                      bsStyle="warning"
                      label="Reset"
                      fill
                      loading={isResettingTableConfiguration}
                      onClick={this.resetTableConfiguration}
                    />
                    <CustomButton
                      bsSize="sm"
                      fill
                      bsStyle="info"
                      label="Save"
                      loading={isSavingTableConfiguration}
                      onClick={this.saveTableConfiguration}
                    />
                  </div>
                </div>

                <Row>
                  <FormInput
                    colProps={{ xs: 12, sm: 3, md: 2 }}
                    value={pageSize}
                    field="pageSize"
                    select={selectNbOfRowsToDisplay}
                    showPlaceholder={false}
                    hideClear
                    onSetState={({ pageSize }) => this.onSetTableConfiguration({ pageSize })}
                  />
                  <FormInput
                    colProps={{ xs: 12, sm: 3, md: 2 }}
                    value={sideView}
                    field="sideView"
                    type="checkbox"
                    label="Open links in side view"
                    showPlaceholder={false}
                    onSetState={({ sideView }) => this.onSetTableConfiguration({ sideView })}
                  />
                  <FormInput
                    colProps={{ xs: 12, sm: 6 }}
                    value={advancedSearchInModal}
                    field="advancedSearchInModal"
                    label="Display search filters in a popup"
                    type="checkbox"
                    showPlaceholder={false}
                    onSetState={({ advancedSearchInModal }) => this.onSetTableConfiguration({ advancedSearchInModal })}
                  />
                </Row>

                <Row className="mb-5px">
                  <Col xs={12} className="form-group w-100 mb-0">
                    <label>{loc("Columns")}</label>
                  </Col>
                  <Col xs={12}>
                    {columns
                      ?.filter(col => (!col.hidden || (col.hidden && col.available)) && col.name !== "action")
                      .map((_configuredOrDefaultColumn, index) => {
                        const configuredOrDefaultColumn =
                          typeof _configuredOrDefaultColumn === "string" ? { name: _configuredOrDefaultColumn } : _configuredOrDefaultColumn
                        const { title, name: colName, hidden: colHidden } = configuredOrDefaultColumn || {}
                        const userColumn = userColumns.find(userColumn => userColumn.name === colName)
                        const hidden = userColumn?.hidden ?? colHidden
                        const onClickToggleColumn = () =>
                          this.onSetTableConfiguration({
                            selectedColumn: {
                              // some columns have dots in their name
                              [colName?.replaceAll(".", "¤")]: hidden,
                            },
                          })

                        return (
                          <div key={index} className="flex align-items-center">
                            {/*
                              Accessibility: tab navigation: no need to allow selecting this icon because it can only be used with a mouse.
                              TODO: implement drag and drop, no hurry.
                            */}
                            {/* <i className="icn-grip-vertical icn-xs text-muted c-grab" /> */}

                            <ButtonWithTooltip
                              bsStyle="primary"
                              className="icn-arrow-down icn-sm"
                              disabled={index === nbOfConfiguredOrDefaultColumns - 1}
                              onClick={() => this.moveColumn({ colName, down: true })}
                              btnClassName="mr-0 flex-center"
                              tooltip={"Move column down"}
                            />
                            <ButtonWithTooltip
                              bsStyle="primary"
                              disabled={index === 0}
                              className="icn-arrow-up icn-sm"
                              onClick={() => this.moveColumn({ colName })}
                              btnClassName="mr-0 flex-center"
                              tooltip={"Move column up"}
                            />
                            <ButtonWithTooltip
                              bsStyle="primary"
                              className={`icn-checkbox${hidden ? "" : "-checked"} icn-sm`}
                              onClick={onClickToggleColumn}
                              btnClassName="mr-0 flex-center"
                              tooltip={hidden ? "Show column" : "Hide column"}
                            />

                            {/* Accessibility: No need to allow focusing this block, the checkbox is enough for the tab navigation. This clickable div is for confort. */}
                            <div className="ml-5px c-pointer grow max-w-screen-xs" onClick={onClickToggleColumn}>
                              {loc(title || colName)}
                            </div>
                          </div>
                        )
                      })}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}

        {isKanbanView ? (
          <Row>
            <Col xs={12}>
              <Suspense fallback={null}>
                <Kanban
                  data={data}
                  pinnedData={pinnedData}
                  entityName={entityName}
                  columns={columns}
                  loading={loading}
                  sideView={sideView}
                  draggable
                  onDragStart={onDragStart}
                  onDrop={onDrop}
                  hasSearchResultsOnHiddenFields={hasSearchResultsOnHiddenFields}
                />
              </Suspense>
            </Col>
          </Row>
        ) : (
          this.getTables({
            bordered,
            id,
            bsClass,
            onRowClick,
            hover,
            showHeaders,
            columnHeaders,
            hasDataRows,
            dataRows,
            dataRowsTypeFieldBreakdown,
            loading,
            nbOfColumns,
            typeFieldBreakdownKeys,
            hasTypeFieldBreakdown,
            breakdown,
            hasSearchResultsOnHiddenFields,
            nbOfTypeFieldBreakdownKeys,
            nbPages,
            pageNumber,
            totalItems,
            rowsCount,
            paginationParams,
            getEntities,
          })
        )}
      </div>
    )
  }
}

export default withRouter(Table)

/**
 * This function is intended to be used with the Table component.
 * It merges the properties of the base columns into the configured columns.
 * The columns defined as hidden are hidden by the Table component.
 * This way it can know which columns to show for the table configuration.
 * @param {*[]} columns
 * @param {*[]} configColumns
 * @returns []
 */
export function mergeColumns(defaultColumns = [], configColumns = []) {
  if (typeof configColumns === "string") configColumns = configColumns.split(",")
  if (configColumns.length === 0) return defaultColumns
  return configColumns
    .filter(configCol => configCol)
    .map(configCol => {
      const _configCol = typeof configCol === "string" ? { name: configCol } : configCol
      const defaultColumn = defaultColumns.filter(col => col).find(defaultCol => defaultCol.name === _configCol.name)
      // If a column is defined in the config, that also exists in the default columns with the hidden property,
      // then we assume that the configured column hidden property or lack of of it takes precedence for the display.
      if (defaultColumn) delete defaultColumn.hidden
      const retColumn = { ...defaultColumn, ..._configCol }
      if (Number.isInteger(_configCol.width)) retColumn.className = `w-${_configCol.width}`
      return retColumn
    })
}

function extractColData(col) {
  if (!col) return col
  if (typeof col === "string") {
    if (col.endsWith(" KB")) return Number(col.replace(" KB", "")) * 1024 // kilobytes
    if (col.endsWith(" MB")) return Number(col.replace(" MB", "")) * 1024 * 1024 // megabytes
    if (col.endsWith(" GB")) return Number(col.replace(" GB", "")) * 1024 * 1024 * 1024 // gigabytes
    return col.toUpperCase()
  }
  if (typeof col === "number") return col

  if (React.isValidElement(col)) {
    const { registration, type, value, select, children, obj = {}, field } = col.props // <FormInput/> props
    if (!value && field) {
      if (typeof obj[field] === "string") return obj[field].toUpperCase()
      return obj[field]
    }
    if (!value && registration) return registration // <PersonLink/>

    if (typeof children === "string") return children.toString().toUpperCase()
    if (Array.isArray(children)) for (const child of children) if (typeof child === "string") return child.toString().toUpperCase()

    if (value) {
      if (["bytes", "milliseconds"].includes(type)) return value
      if (type === "date" || type === "datetime") return value.getTime()
      if (type === "percentage") return value
      if (select) return (getLabel(select, value) || "").toUpperCase()
      return value
    }
  }
}

function getDateTime(date, locale) {
  if (!date) return date
  if (date instanceof Date) return date.getTime()
  try {
    // Support datetime automatic sorting (but without the time for the moment)
    if (date.includes(",")) date = date.split(",")[0]?.trim()
    if (date.includes("à")) date = date.split("à")[0]?.trim()
    if (date.includes(" ")) date = date.split(" ")[0]?.trim()

    return utcParseDate(date, locale)?.getTime()
  } catch (error) {
    console.error("getDateTime()", error)
  }
  return 0
}

function compareTableItems(obj1, obj2, key, locale) {
  let columnType
  let obj1Target = get(obj1, key)
  let obj2Target = get(obj2, key)
  if (key?.toLowerCase().includes("date")) columnType = "date"
  else if (key?.toLowerCase().includes("number")) columnType = "number"
  else if (key?.toLowerCase().includes("amount")) columnType = "currency"
  else if (React.isValidElement(obj1Target)) columnType = obj1Target.props?.type

  let a
  let b
  if (columnType === "date") {
    if (React.isValidElement(obj1Target)) a = getDateTime(obj1Target.props?.value, locale)
    else a = getDateTime(obj1Target, locale)

    if (React.isValidElement(obj2Target)) b = getDateTime(obj2Target.props?.value, locale)
    else b = getDateTime(obj2Target, locale)
  } else if (columnType === "currency") {
    if (React.isValidElement(obj1Target)) a = obj1Target.props?.value
    else a = unformatCurrency(obj1Target)

    if (React.isValidElement(obj2Target)) b = obj2Target.props?.value
    else b = unformatCurrency(obj2Target)
  } else if (columnType === "number") {
    if (React.isValidElement(obj1Target)) a = obj1Target.props?.value
    else a = Number(obj1Target || 0) || obj1Target

    if (React.isValidElement(obj2Target)) b = obj2Target.props?.value
    else b = Number(obj2Target || 0) || obj2Target
  } else {
    a = extractColData(obj1Target)
    b = extractColData(obj2Target)
  }

  if (!a) {
    if (typeof b === "string") a = ""
    else if (typeof b === "number") a = 0
  } else if (!b) {
    if (typeof a === "string") b = ""
    else if (typeof a === "number") b = 0
  }

  if (a > b) return 1
  else if (a < b) return -1
  return 0
}

function sortTableData(tableData = [], sorting = {}) {
  const locale = getLocale()

  Object.keys(sorting)
    .filter(key => sorting[key] !== undefined)
    .forEach(key => {
      if (sorting[key]) tableData.sort((a, b) => compareTableItems(a, b, key, locale))
      else tableData.sort((a, b) => compareTableItems(b, a, key, locale))
    })

  tableData
    .filter(it => it.content)
    .forEach(it => {
      const index = tableData.findIndex(d => d._linkedIndex === it._linkedIndex)
      const position = tableData.findIndex(d => d._index === it._linkedIndex)
      tableData.splice(index, 1)
      tableData.splice(position + 1, 0, it)
    })
}
