import decline from "./declension/index.mjs"
import { removeAllAccents } from "./miscUtils.mjs"

/**
 * @param {string} str
 * @returns Returns the provided string with the first letter capitalized.
 */
function capitalizeFirstLetter(str) {
  if (!str) return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * @param {string} str
 * @returns Returns the provided string with the first letter in lower case.
 */
function lowerCaseFirstLetter(str) {
  if (!str) return str
  return str.charAt(0).toLowerCase() + str.slice(1)
}

function truncate(str, maxLength = 25, ellipsis = "...") {
  if (!str) return
  if (str.length <= maxLength + 3) return str
  return str.substring(0, maxLength) + ellipsis
}

/**
 * Replace 8239 and 160 character codes by 32 " " (normal space)
 * in order to avoid problems with replace regex function
 */
function replaceASCII(str) {
  const arr = []
  if (str) {
    for (let i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) === 8239 || str.charCodeAt(i) === 160) arr.push(" ")
      else arr.push(str.charAt(i))
    }
  }
  return arr.join("")
}

/**
 * @param {string} string
 * @param {size=2} size
 * @returns Returns the provided string right-padded with spaces up to the specified size.
 */
function padStringEnd(string = "", size) {
  while (string.length < size) string += " "
  return string
}

/**
 * @param {string} str
 * @returns Returns the given string stripped of spaces and tabs and trimmed.
 */
function trim(str) {
  if (!str || typeof str !== "string") return str
  return str
    .replace(/ /g, "") // Spaces
    .replace(/\t/g, "") // Tabs
    .replace(/\r/g, "") // Carriage returns
    .replace(/\n/g, "") // New lines
    .replace(/\u00A0/g, "") // Non-breaking space
    .replace(/\u2003/g, "") // Em spaces
    .trim()
}

/**
 * @param {string} word
 * @param {string} declension
 * @param {string} locale currently only supports lt-LT
 * @returns Returns the word declined.
 */
function declineWord(word, declension, locale) {
  if (!word || !declension || !locale) return word
  return decline(word, declension, locale.split("-")[0])
}

/**
 * @param {string} name
 * @returns Returns the given camelCase expression as a sentence. Example: "theDogJumpedOverThePond" -> "The dog jumped over the pond"
 */
function labelFromName(name) {
  if (!name) return ""
  let label = ""
  for (let i = 0; i < name.length; i++) {
    if (i === 0) {
      label += name[0].toUpperCase()
    } else if (name[i] >= "A" && name[i] <= "Z") {
      label += " " + name[i].toLowerCase()
    } else {
      label += name[i]
    }
  }
  return label
}

/**
 * Replaces the last occurrence of a target substring in a given string.
 *
 * @param {string} str - The original string where the replacement will happen.
 * @param {string} target - The substring that you want to replace.
 * @param {string} replacement - The string that will replace the target substring.
 * @returns {string} The modified string with the last occurrence of the target replaced by the replacement.
 *                   If the target substring is not found, the original string is returned unchanged.
 *
 */
function replaceLast(str, target, replacement) {
  const lastIndex = str.lastIndexOf(target)
  if (lastIndex === -1) return str // Return original string if target is not found

  return str.slice(0, lastIndex) + replacement + str.slice(lastIndex + target.length)
}

function isString(value) {
  return typeof value === "string" || value instanceof String
}

/**
 * @param {string} str
 * @returns Returns the given string as camelCase expression. Example: "The dog jumped over the pond" -> "theDogJumpedOverThePond"
 */
function toCamelCase(str) {
  if (!str) return str
  str = str
    .replace(/\//g, " ")
    .replace(/\./g, "")
    .replace(/_/g, " ")
    .replace(/:/g, "")
    .replace(/%/g, "Percent")
    .replace(/\+/g, "And")
    .replace(/>/g, "After")
    .replace(/</g, "Before")
    .replace(/&/g, "And")
  str = str.replace(/-/g, " ")
  str = str.replace(/'/g, "")
  str = str.replace(/\(/g, "")
  str = str.replace(/\)/g, "")
  let words = str.split(" ")
  if (words.length === 0) return ""
  let name = words[0].toLowerCase()
  for (let i = 1; i < words.length; i++) if (words[i].length > 0) name += words[i][0].toUpperCase() + words[i].substring(1).toLowerCase()
  return name
}

/**
 * @param {string} label
 * @returns Returns the given sentence as camelCase expression (without accents). Example: "Le chien a sauté du pont" -> "leChienASauteDuPont"
 */
function labelToName(label) {
  if (!label || !isString(label)) return ""
  return toCamelCase(removeAllAccents(label))
}

export {
  capitalizeFirstLetter,
  lowerCaseFirstLetter,
  truncate,
  replaceASCII,
  padStringEnd,
  trim,
  declineWord,
  labelFromName,
  replaceLast,
  isString,
  toCamelCase,
  labelToName,
}
