import axios from "axios"
import { getError, getWarning, PROPS_, replaceVariables } from "basikon-common-utils"
import debounce from "lodash.debounce"
import get from "lodash.get"
import React, { createRef } from "react"
import { Col, ControlLabel, FormGroup, OverlayTrigger, Tooltip } from "react-bootstrap"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import DebugPopover from "@/_components/DebugPopover"
import FormInput, { getHelperDisplay, getMandatoryLabel, getSelectComponents, updateFieldProps } from "@/_components/FormInput"
import FormInputAddress from "@/_components/FormInputAddress"
import FormInputBankAccount from "@/_components/FormInputBankAccount"
import FormInputEntitySearch from "@/_components/FormInputEntitySearch"
import FormInputScanIban from "@/_components/FormInputScanIban"
import HistoryModal from "@/_components/HistoryModal"
import NumbersTableComponent from "@/_components/NumbersTableComponent"

import { syncFieldProps, toPatch } from "@/_services/inputUtils"
import { getList, getRichValues, getValues } from "@/_services/lists"
import { loc } from "@/_services/localization"
import { getUsername } from "@/_services/userConfiguration"
import { debug, handleAccessibleOnKeyDown, labelFromName } from "@/_services/utils.js"
import PersonRegistrationComponent from "@/person/PersonRegistrationComponent"

const keyboardNegativeValueCssClass = "keyboard-select-negative-value"

class FormInputExtended extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      displayValue: undefined,
      loadedRegistration: undefined,
      selectListIsLoaded: undefined,
    }

    this.keyboardSelectedNegativeValue = createRef()
  }

  componentDidMount() {
    const { field, mandatory } = this.props
    const { multiple, select, obj } = this.getProps()
    syncFieldProps({ obj, field, mandatory })

    if (multiple && select && typeof select === "string") {
      getList(replaceVariables(select, obj), () => this.setState({ selectListIsLoaded: true }))
    }
  }

  componentWillUnmount() {
    const { obj, field } = this.props
    syncFieldProps({ obj, field, mandatory: false, min: null, max: null })
  }

  handleSetHistoryState = patch => {
    const { obj, field, onSetState } = this.props
    if (!patch) return

    const array = [...(obj[field] || [])]
    const value = patch[field]

    if (array.length === 0 || (array[0]._id && array[0].value !== value)) {
      // insert changed value at index 0
      array.splice(0, 0, null)
    }

    array[0] = {
      date: new Date(),
      value,
      username: getUsername(),
    }

    patch = { [field]: array }
    onSetState(patch)
  }

  handleSetSelectMultipleState = (newValues, options) => {
    const { mandatory, label } = this.getProps()

    const values =
      newValues?.map(v => {
        if (this.keyboardSelectedNegativeValue.current === v.value) {
          return `-${v.value}`
        }
        return v.value
      }) || []

    // keep order
    const orderedValues = options
      .map(option => {
        if (values.includes(option.value)) return option.value
      })
      .filter(it => it || it === 0)

    // also keep "???" values, if any, at the end
    for (const value of values) {
      if (!options.find(option => option.value === value)) orderedValues.push(value)
    }

    const { obj, field, onSetState } = this.props
    const patch = field?.includes(".") ? toPatch(obj, field, orderedValues) : { [field]: orderedValues }

    const errorFormat = mandatory && !orderedValues.length && getMandatoryLabel(label)
    updateFieldProps(patch, { obj, field, mandatory, errorFormat })

    onSetState(patch)
  }

  withHistoryInput = () => {
    const { showHistoryModal } = this.state
    const allProps = this.getProps()
    let { obj, field, withHistory, label, modelFieldPath } = allProps

    // this goes with a schema field such as <fieldName>: [{ date, value, username }] - see person.lossGivenDefault for example
    label = (
      <>
        <ControlLabel htmlFor={modelFieldPath || field}>{label || labelFromName(field)}</ControlLabel>
        <OverlayTrigger placement="top" overlay={<Tooltip id="history">{loc(withHistory.title || "History")}</Tooltip>}>
          <i className="icn-history icn-xs c-pointer text-info ml-5px" onClick={() => this.setState({ showHistoryModal: true })} />
        </OverlayTrigger>
      </>
    )

    const value = Array.isArray(obj[field]) ? obj[field]?.[0]?.value : obj[field]
    const props2 = { ...allProps, obj: { [field]: value } }

    return (
      <>
        <FormInput {...{ ...props2, label, onSetState: this.handleSetHistoryState }} />
        {showHistoryModal && (
          <HistoryModal
            data={withHistory.data}
            title={withHistory.title}
            columns={withHistory.columns}
            {...allProps}
            onClose={() => this.setState({ showHistoryModal: false })}
          />
        )}
      </>
    )
  }

  multipleInput = ({ DebugOverlay }) => {
    const allProps = this.getProps()
    const {
      obj,
      field,
      value,
      label,
      select,
      colProps,
      placeholder,
      disabled,
      inArray,
      readOnly,
      creatable,
      richValuesList,
      modelPath,
      modelFieldPath,
      hideClear,
      showUnknownLabelHint = true,
      handleNegativeValues,
      displayMode,
      handleClear,
      helper,
      noOptionsMessage = () => loc("No data"),
      mandatory,
      touched,
      menuPlacement,
    } = allProps

    let options = []
    if (typeof select === "string") {
      const _getValues = richValuesList ? getRichValues : getValues
      options = _getValues(replaceVariables(select, obj), () => this.setState({ selectListIsLoaded: true })) // same as <FormInput/>
    }

    if (Array.isArray(select)) {
      if (typeof select[0] === "string" || typeof select[0] === "number") {
        options = select.map(it => ({ value: it, label: it.toString() }))
      } else {
        options = select
      }
    }

    const selectedValues = (value || (field?.includes(".") ? get(obj, field, []) : obj?.[field]) || [])
      .filter?.(v => v || v === 0)
      .map(_value => {
        const code = typeof _value === "object" ? _value.value : _value
        const found = options.find(cs => cs.value === code)
        if (found) return found

        // This is mostly for the advanced search
        // so that negative filters display the label of their positive counterpart.
        // To reduce the scope where we treat the first character minus as a special character,
        // we only consider this case when the appropriate props is passed.
        if (handleNegativeValues) {
          const negativeFound = options.find(cs => `-${cs.value}` === code)
          if (negativeFound) {
            return {
              ...negativeFound,
              value: code,
              label: `[-] ${negativeFound.label}`,
            }
          }
        }

        return {
          value: code,
          label: creatable ? code : showUnknownLabelHint ? `${code} (???)` : code,
        }
      })

    if (handleNegativeValues) {
      options = options.filter(option => {
        return !selectedValues.find(selectedValue => selectedValue.value === `-${option.value}`)
      })
    }

    if (readOnly) {
      if (displayMode === "badge") {
        return (
          <span className="min-h-24px border-1px br-theme border-solid border-gray-lighter font-1-2 leading-2-4 mr-5px mt-5px pdr-5px inline-flex flex-align-center flex-wrap">
            <span className="flex flex-wrap">
              {typeof handleClear === "function" && (
                <div
                  className="inline-flex-center max-h-24px min-h-24px max-w-24px min-w-24px text-danger c-pointer"
                  tabIndex="0"
                  onClick={handleClear}
                  onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: handleClear })}
                >
                  <i className="icn-xmark icn-xxs" />
                </div>
              )}
              <span className="font-weight-bold mr-5px">{label}:</span>
            </span>
            {selectedValues.map(selectedValue => selectedValue.label).join(", ")}
          </span>
        )
      }

      if (displayMode === "raw") {
        return (
          <>
            {label && <span className="font-weight-bold mr-5px">{label}:</span>}
            <span>{selectedValues.map(selectedValue => selectedValue.label).join(", ")}</span>
          </>
        )
      }
    }

    const ariaLabel = label || field

    const isDisabled = disabled || readOnly

    const formControl = creatable ? (
      <CreatableSelect
        id={modelFieldPath}
        noOptionsMessage={noOptionsMessage}
        className="select-group"
        classNamePrefix="select"
        components={getSelectComponents({ isClearable: true })}
        closeOnSelect={false}
        isMulti={true}
        placeholder={loc(placeholder || "Select") + "..."}
        formatCreateLabel={text => `${loc`Create`} "${text}"`}
        value={selectedValues}
        options={options}
        onChange={newValues => this.handleSetSelectMultipleState(newValues, options)}
        isDisabled={isDisabled}
        aria-label={ariaLabel}
        aria-labelledby={modelFieldPath}
      />
    ) : (
      <Select
        id={modelFieldPath}
        noOptionsMessage={noOptionsMessage}
        className="select-group"
        classNamePrefix="select"
        onKeyDown={event => {
          // all this stuff is for accessibility, to enable keyboard selection of negative values
          const isLeftArrowKey = event.keyCode === 37
          const isRightArrowKey = event.keyCode === 39
          if (isLeftArrowKey || isRightArrowKey) {
            const selectedOption = document.getElementsByClassName("select__option--is-focused")?.[0]
            if (selectedOption) {
              event.preventDefault()
              const keyboardSelectedNegativeValue = selectedOption.children?.[0]?.getAttribute("data-select-option-value")
              if (isLeftArrowKey) {
                selectedOption.classList.remove(keyboardNegativeValueCssClass)
                this.keyboardSelectedNegativeValue.current = null
              } else {
                selectedOption.classList.add(keyboardNegativeValueCssClass)
                this.keyboardSelectedNegativeValue.current = keyboardSelectedNegativeValue
              }
            }
          }
        }}
        components={getSelectComponents({ isClearable: !hideClear, handleNegativeValues, selectedValues })}
        closeOnSelect={false}
        isMulti
        placeholder={loc(placeholder || "Select") + "..."}
        value={selectedValues}
        options={options}
        onChange={newValues => this.handleSetSelectMultipleState(newValues, options)}
        isDisabled={isDisabled}
        tabSelectsValue={false}
        aria-label={ariaLabel}
        aria-labelledby={modelFieldPath}
        menuPlacement={menuPlacement}
      />
    )

    const warningMessage = getWarning(obj, field)
    let errorDisplay = warningMessage && <span className="validation-warning">{loc(warningMessage)}</span>

    const errorMessage = mandatory && touched && !selectedValues.length ? getMandatoryLabel(label) : getError(obj, field)
    errorDisplay = errorMessage && <span className="validation-error">{loc(errorMessage)}</span>

    const helperDisplay = getHelperDisplay({ helper })

    const translatedLabel = loc(label) ?? labelFromName(field)
    if (!translatedLabel || inArray) {
      if (colProps) {
        return (
          <Col {...colProps}>
            {formControl}
            {helperDisplay}
            {errorDisplay}
          </Col>
        )
      }

      return (
        <>
          <DebugOverlay />
          {formControl}
          {helperDisplay}
          {errorDisplay}
        </>
      )
    } else {
      const formInput = (
        <FormGroup data-model-field-path={(modelPath ? modelPath + "." : "") + field || modelFieldPath}>
          <ControlLabel>
            {translatedLabel}
            <DebugOverlay />
          </ControlLabel>
          {formControl}
          {helperDisplay}
          {errorDisplay}
        </FormGroup>
      )

      if (colProps) return <Col {...colProps}>{formInput}</Col>
      return formInput
    }
  }

  numbersTable = ({ DebugOverlay }) => {
    const props = this.getProps()
    let { label, colProps, field } = props

    const ratesTableControl = (
      <FormGroup>
        <ControlLabel>
          {label || labelFromName(field)}
          <DebugOverlay />
        </ControlLabel>
        <NumbersTableComponent {...props} />
      </FormGroup>
    )

    if (colProps) return <Col {...colProps}>{ratesTableControl}</Col>
    return ratesTableControl
  }

  getDebugOverlay = () => {
    const {
      field,
      select,
      debugUrlQuery,
      debugScriptList, // if a list is provided by a script, use this to explicitely declare it
      help,
      modelFieldPath,
      modelPath,
    } = this.getProps()

    return debug ? (
      <DebugPopover
        debugUrlQuery={debugUrlQuery}
        debug={debug}
        help={help}
        modelFieldPath={(modelPath ? modelPath + "." : "") + field || modelFieldPath}
        select={select}
        debugScriptList={debugScriptList}
      />
    ) : null
  }

  getProps = () => {
    const { field: fieldName, obj: controlledObject = {} } = this.props

    let configProps
    // "addresses[0].address1" => "addresses[0].props_address1"
    if (fieldName?.includes(".")) {
      const subFieldNames = fieldName.split(".")
      subFieldNames[subFieldNames.length - 1] = `${PROPS_}${subFieldNames[subFieldNames.length - 1]}`

      configProps = get(controlledObject, subFieldNames.join("."))
    } else {
      configProps = fieldName && controlledObject[PROPS_ + fieldName]
    }

    if (configProps) return { ...this.props, ...configProps }
    return this.props
  }

  render() {
    const props = this.getProps()
    const { withHistory, multiple, hidden, type, searchEntityName, withDetails = true } = props

    if (hidden === true) return null

    const inputParams = {
      DebugOverlay: this.getDebugOverlay,
    }

    if (searchEntityName) return <FormInputEntitySearch DebugOverlay={inputParams.DebugOverlay} {...props} />
    if (withHistory) return this.withHistoryInput(inputParams)
    // the multiple option is also used by the type file, which is handled directly by FormInput
    if (multiple && type !== "file") return this.multipleInput(inputParams)
    if (["ratesTable", "coefficientsTable", "costTable", "table"].includes(type)) return this.numbersTable(inputParams)
    if (type === "iban") return <FormInputScanIban {...props} />
    if (type === "address") return <FormInputAddress {...props} />
    if (type === "bankAccount") return <FormInputBankAccount {...props} />
    /** Example in a page :
     *  {
          formInputProps: {
            obj: "deliveryAddress",
            type: "address",
            value: pageState.deliveryAddress?.addressLine,
          }
        }
     */

    if (type === "personRegistration") return <PersonRegistrationComponent {...props} />
    /** Example in a page :
     *  {
          formInputProps: {
            obj: "personRegistration",
            type: "personRegistration",
            person: { ...pageState.personRegistration },
          }
        }
     */

    if (type === "siren") {
      const loadOptions = async value => value && (await axios.post(`/api/external/pivot/person-registration/SIR`, { value, withDetails })).data
      return (
        <FormInput
          select={
            loadOptions &&
            debounce((query, callback) => {
              loadOptions(query).then(resp => callback(resp))
            }, 300)
          }
          {...props}
        />
      )
    }

    return <FormInput {...props} />
  }
}

export default FormInputExtended
