import { getEntityClientRoute, getFilterFunction, pruneObject, toQueryString } from "basikon-common-utils"
import React, { createRef } from "react"
import { Grid } from "react-bootstrap"

import Card from "@/_components/Card"
import CustomButton from "@/_components/CustomButton"
import LayoutCard from "@/_components/LayoutCard"
import Table, { mergeColumns } from "@/_components/Table"

import { getEntityDisplay } from "@/_services/entity"
import { getLabel, getList } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { fetchPinnedEntities, getPageConfig } from "@/_services/userConfiguration"
import {
  applyPageAdvancedSearchConfig,
  checkOrganizationNameInclude,
  getClientRouteKey,
  getEntities,
  hasQueryParamsChanged,
  mergeQueryParams,
  searchParamToObject,
  toQueryParams,
} from "@/_services/utils"

class CommitteesPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      location: { pathname: clientRoute },
    } = props

    const entityName = "Committee"
    const pageConfigKey = getClientRouteKey(clientRoute) || `${entityName}sPage`
    const pageConfig = getPageConfig(pageConfigKey)
    const defaultColumns = [
      { title: "Registration", name: "registration", linkTo: "/committee/{registration}" },
      { title: "Organization", name: "orgaRegistration", hidden: true },
      { title: "Status", name: "status", badge: true, select: "committeeStatus" },
      { title: "Type", name: "type", badge: true, select: "committeeType" },
      { title: "Name", name: "name", hidden: true },
      { title: "Subject", name: "subject" },
      { title: "Projects", name: "nbProjects" },
      { title: "Persons", name: "nbPersons" },
      { title: "Invited", name: "nbInvitedPersons" },
      { title: "Accepted", name: "nbAcceptedPersons" },
      { title: "Refused", name: "nbRefusedPersons" },
      { title: "Presented", name: "nbPresentedPersons", hidden: true },
      { title: "Absent", name: "nbAbsentPersons", hidden: true },
      { title: "Start date", name: "startDate", type: "datetime" },
      { title: "End date", name: "endDate", type: "datetime", hidden: true },
      { title: "Committee insertion date", name: "_insertDate", type: "date", hidden: true },
    ]

    this.state = {
      committees: [],
      loading: false,
      entityName,
      pinnedData: [],
      entityClientRoute: getEntityClientRoute(entityName),
      pageConfigKey,
      pageConfig,
      columns: mergeColumns(defaultColumns, pageConfig?.columns),
      defaultColumns,
      paginationParams: null,
    }

    this.preventAutoRefresh = createRef()
  }

  componentDidMount() {
    const { pageConfigKey } = this.state
    this.getCommittees()

    getList("committeeStatus", () => this.setState({ loaded: true }))

    applyPageAdvancedSearchConfig(pageConfigKey, committeeSearchFields)
  }

  componentDidUpdate(prevProps) {
    if (!this.preventAutoRefresh.current && hasQueryParamsChanged(this.props, prevProps)) {
      this.getCommittees()
    }
  }

  getCommittees = async ({ params, isAdvancedSearch, nextPagination } = {}) => {
    const { location, history } = this.props
    const { columns, entityName, committees: stateEntities, pageConfig } = this.state

    const queryParams = { ...searchParamToObject(location.search), ...(params || {}) }
    const getEntitiesQueryParams = {
      ...pageConfig?.defaultQuery,
      ...pruneObject(queryParams),
      ...pageConfig?.query,
    }
    getEntitiesQueryParams.include = checkOrganizationNameInclude(columns, getEntitiesQueryParams.include)

    this.setState({ loading: true })

    if (nextPagination) {
      const { data: moreEntities, paginationParams } = await getEntities(entityName, getEntitiesQueryParams, { nextPagination })
      this.setState({ committees: [...stateEntities, ...moreEntities], paginationParams, loading: false })
      this.preventAutoRefresh.current = true
      delete queryParams.range
      history.replace(`${location.pathname}${toQueryString(queryParams)}`)
      this.preventAutoRefresh.current = false
      return
    }

    const [{ data: committees, paginationParams }, pinnedData] = await Promise.all([
      getEntities(entityName, getEntitiesQueryParams),
      fetchPinnedEntities({ entityName, queryParams: getEntitiesQueryParams }),
    ])
    this.setState({ committees, paginationParams, pinnedData: this.formatTableData(pinnedData), loading: false })

    if (params) {
      this.preventAutoRefresh.current = true
      if (isAdvancedSearch) queryParams.page = 1
      history.replace(`${location.pathname}${mergeQueryParams(location.search, queryParams)}`)
      this.preventAutoRefresh.current = false
    }
  }

  formatTableData = data => {
    return data.map(committee => {
      const { persons = [], projects = [] } = committee

      let nbInvitedPersons = 0
      let nbAcceptedPersons = 0
      let nbRefusedPersons = 0
      let nbPresentedPersons = 0
      let nbAbsentPersons = 0
      for (const person of persons) {
        if (person.inviteStatus !== "INTENDED") nbInvitedPersons += 1
        if (person.inviteStatus === "PRESENTED") nbPresentedPersons += 1
        if (person.inviteStatus === "ABSENT") nbAbsentPersons += 1
        if (person.rsvp === "ACCEPTED") nbAcceptedPersons += 1
        if (person.rsvp === "REFUSED") nbRefusedPersons += 1
      }

      return {
        registration: committee.registration,
        status: committee.status,
        type: committee.type,
        name: committee.name,
        subject: committee.subject,
        organization: committee.orgaRegistration,
        nbProjects: projects.length,
        nbPersons: persons.length,
        nbInvitedPersons,
        nbAcceptedPersons,
        nbRefusedPersons,
        nbPresentedPersons,
        nbAbsentPersons,
        startDate: committee.startDate,
        comment: committee.comment,
        _insertDate: committee?._insertDate,
      }
    })
  }

  render() {
    const { committees = [], loading, pageConfig, columns, defaultColumns, entityClientRoute, entityName, pinnedData } = this.state
    const { location, history } = this.props

    const title = loc(pageConfig?.title || "Committees")

    const queryParams = searchParamToObject(location.search)
    const filteredCommittes = queryParams.filter?.trim() ? filterCommittees(committees, queryParams.filter) : committees

    const data = this.formatTableData(filteredCommittes)

    return (
      <Grid className="committees-page">
        {!pageConfig?.hideKpis && (
          <LayoutCard noCard rows={[{ type: "content", props: { getContentOnUrlChange: false, name: "committees-kpi", noCard: false } }]} />
        )}

        <Card
          title={title}
          action={
            <CustomButton
              fill
              pullRight
              bsSize="small"
              bsStyle="primary"
              className="inline-flex-center"
              hidden={pageConfig?.addButton?.hidden}
              onClick={() => {
                let addButtonLink = pageConfig?.addButton?.linkTo || entityClientRoute
                if (pageConfig?.addButton?.includeUrlQueryParams) addButtonLink += toQueryString(toQueryParams(queryParams))
                history.push(addButtonLink)
              }}
            >
              <i className="icn-plus icn-xs mr-5px" />
              {loc(pageConfig?.addButton?.label || "Add")}
            </CustomButton>
          }
        >
          <Table
            useSearchRowComponent
            getEntities={this.getCommittees}
            searchFields={committeeSearchFields}
            exportDataFileName={title}
            loading={loading}
            columns={columns}
            defaultColumns={defaultColumns}
            data={data}
            entityName={entityName}
            pinnedData={pinnedData}
            pageConfig={pageConfig}
            showRowsCount
          />
        </Card>
      </Grid>
    )
  }
}

export default CommitteesPage

export const committeeSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, md: 6 }, searchEntityName: "Committee", regex: true },
    { field: "name", colProps: { xs: 12, md: 6 }, regex: true },
    { field: "status", type: "multiple", select: "committeeStatus", colProps: { xs: 12, md: 6 } },
    { field: "type", type: "multiple", select: "committeeType", colProps: { xs: 12, md: 6 } },
  ],
  [
    { field: "orgaRegistration", label: "Organization", searchEntityName: "Person", query: { type: "O" }, colProps: { xs: 12, md: 4 }, regex: true },
    { field: "ownerRegistration", colProps: { xs: 12, md: 4 }, regex: true },
    { field: "subject", colProps: { xs: 12, md: 4 }, regex: true },
  ],
]

export function filterCommittees(committees, filter) {
  if (!filter) return committees

  const locale = getLocale()
  const includes = getFilterFunction(filter, locale)

  return committees.filter(committee => {
    const statusLabel = getLabel("committeeStatus", committee.status)

    const { persons = [] } = committee
    const { personRegistration: partnerRegistration, person: partner } = persons.find(p => p.role === "PARTNER") || {}

    return (
      includes(statusLabel) ||
      includes(committee.name) ||
      includes(committee.rate) ||
      includes(committee.best) ||
      includes(partner?.name) ||
      includes(committee.status) ||
      includes(committee.comment) ||
      includes(committee.cessionDate) ||
      includes(committee.deliveryDate) ||
      includes(partnerRegistration) ||
      includes(committee.registration) ||
      includes(committee.assignedUser) ||
      includes(committee.contract?.name) ||
      includes(committee.fundRegistration) ||
      includes(getEntityDisplay(committee)) ||
      includes(committee.contractRegistration)
    )
  })
}
