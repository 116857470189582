import React from "react"
import { Col, Row } from "react-bootstrap"

import PanelInner from "@/_components/PanelInner"
import CustomButton from "@/_components/CustomButton"
import Table, { mergeColumns } from "@/_components/Table"
import CustomCheckbox from "@/_components/CustomCheckbox"
import PersonContactModal from "@/person/PersonContactModal"
import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import { getLabel, getList } from "@/_services/lists"
import { loc } from "@/_services/localization"

class PersonContacts extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      action: undefined,
      contactIndex: undefined,
    }
  }

  componentDidMount = () => {
    getList("personTitle", () => this.setState({ personTitleListLoaded: true }))
  }

  handleAction = ({ action, index, prevContact }) => {
    const { contactIndex, modalAction } = this.state
    const { person, handleSetPersonState } = this.props
    const contacts = person?.contacts || []

    switch (action) {
      case "add": {
        contacts.push({})
        handleSetPersonState({ contacts })
        this.setState({ contactIndex: contacts.length - 1, showModal: true, modalAction: action })
        break
      }
      case "edit": {
        this.setState({ contactIndex: index, showModal: true, modalAction: action })
        break
      }
      case "delete": {
        const contacts = person?.contacts || []
        contacts.splice(index, 1)
        handleSetPersonState({ contacts })
        break
      }
      case "saveModal": {
        const contact = contacts[contactIndex] || {}

        // Only one contact can be default
        if (contact.isDefault) {
          for (const [index] of contacts.entries()) {
            if (index !== contactIndex) contacts[index].isDefault = false
          }
        }

        handleSetPersonState({ contacts }, true)
        this.setState({ contactIndex: undefined, showModal: false })
        break
      }
      case "closeModal": {
        if (modalAction === "add") contacts.pop() // Remove last
        else this.handleSetContactState(prevContact) // Revert changes

        this.setState({ contactIndex: undefined, showModal: false })
        break
      }
    }
  }

  handleSetContactState = patch => {
    const { contactIndex } = this.state
    const { person, handleSetPersonState } = this.props

    const contacts = person?.contacts || []
    contacts[contactIndex] = { ...(contacts[contactIndex] || {}), ...patch }

    handleSetPersonState({ contacts })
  }

  render() {
    const {
      person,
      readOnly,
      bsStyle = "primary",
      bsSize = "xs",
      title = "Contacts",
      show,
      collapse,
      selection,
      showAddButton = true,
      columns,
      rows,
    } = this.props
    const { contactIndex, showModal } = this.state

    const data = (person?.contacts || [])
      .filter(contact => contact)
      .map((contact, index) => {
        return {
          ...contact,
          isSelected: (
            <CustomCheckbox
              numberId={contact._id}
              disabled={readOnly}
              checked={contact.isSelected}
              onChange={event => this.handleSetContactState({ isSelected: event.target.checked })}
            />
          ),
          name: (
            <>
              {`${getLabel("personTitle", contact.title || "")} ${contact.firstName || ""} ${contact.lastName || ""} ${contact.secondName || ""}`}
              {contact.isDefault && (
                <CustomButton bsStyle="info" bsSize="xs" fill round className="ml-5px">
                  {loc`Default`}
                </CustomButton>
              )}
            </>
          ),
          socialNetworks: (
            <>
              <ButtonWithTooltip
                className="icn-linked-in icn-sm"
                btnClassName="p-0 m-0 btn-simple"
                disabled={!contact.linkedIn}
                onClick={() => window.open(contact.linkedIn, "_blank")}
                tooltip="LinkedIn"
              />
              <ButtonWithTooltip
                className="icn-facebook icn-sm"
                btnClassName="p-0 m-0 btn-simple"
                disabled={!contact.facebook}
                onClick={() => window.open(contact.facebook, "_blank")}
                tooltip="Facebook"
              />
              <ButtonWithTooltip
                className="icn-instagram icn-sm"
                btnClassName="p-0 m-0 btn-simple"
                disabled={!contact.instagram}
                onClick={() => window.open(contact.instagram, "_blank")}
                tooltip="Instagram"
              />
            </>
          ),
          actions: (
            <>
              <ButtonWithTooltip
                tooltip={"Edit"}
                bsSize="xs"
                className="icn-edit icn-xs"
                onClick={() => this.handleAction({ action: "edit", index })}
              />
              {!selection?.select && (
                <ButtonWithTooltip
                  tooltip={"Delete"}
                  bsSize="xs"
                  className="icn-xmark icn-xs"
                  onClick={() => this.handleAction({ action: "delete", index })}
                />
              )}
            </>
          ),
        }
      })

    if (readOnly && data.length === 0) return null

    return (
      <>
        <PanelInner
          collapse={collapse}
          title={loc(title)}
          className={show ? "" : "d-none"}
          buttons={
            !readOnly &&
            showAddButton && (
              <ButtonWithTooltip
                fill
                pullRight
                bsSize={bsSize}
                bsStyle={bsStyle}
                className="icn-plus icn-xs"
                btnClassName="inline-flex-center"
                onClick={() => this.handleAction({ action: "add" })}
              />
            )
          }
        >
          <Row>
            {data.length > 0 && (
              <Col xs={12}>
                <Table
                  overflowX
                  data={data}
                  columns={mergeColumns(
                    [
                      selection?.select && { title: selection?.title ? loc(selection.title) : loc`Select`, name: "isSelected" },
                      { title: loc`Name`, name: "name" },
                      { title: loc`Position`, name: "position", select: "contactPosition" },
                      { title: loc`Phone`, name: "phone" },
                      { title: loc`Email`, name: "email" },
                      { title: loc`Social networks`, name: "socialNetworks" },
                      !readOnly && { title: loc`Actions`, name: "actions", className: "w-5" },
                    ],
                    columns || person?.props_contacts?.columns,
                  )}
                />
              </Col>
            )}
          </Row>
        </PanelInner>

        {showModal && (
          <PersonContactModal
            rows={rows}
            readOnly={readOnly}
            contact={person?.contacts?.[contactIndex]}
            handleSetContactState={this.handleSetContactState}
            onSave={() => this.handleAction({ action: "saveModal" })}
            onClose={prevContact => this.handleAction({ action: "closeModal", prevContact })}
          />
        )}
      </>
    )
  }
}

export default PersonContacts
