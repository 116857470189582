import axios from "axios"
import debounce from "lodash.debounce"

import { defaultLocaleCode, getLocaleParameters, MISSING_TRANSLATION } from "basikon-common-utils"
import { getCollectMissingTranslations, envIsDevelopment, envIsUat } from "@/_services/userConfiguration"

import { getUriAndCacheResponse, removeCachedUriResponse } from "@/_services/cache"
import { htmlTag, localStorageKeys, isEmpty } from "@/_services/utils"

const TMP_KEY = "TMP_KEY"
let currentLocale = ""
let defaultLocalizedStrings = {}
let localizedStrings = {}
let isEnBaseCode = true
let missingTranslationsToAdd = []

export function getLocale() {
  return currentLocale
}

export async function setLocale({ locale, loadStringOverrides = true } = {}) {
  localizedStrings = {}

  let localStorageLocale = localStorage.getItem(localStorageKeys.LOCALE) // values stored locally are always of type string
  if (localStorageLocale === "undefined") localStorageLocale = undefined

  if (missingTranslationsToAdd.length) {
    try {
      await axios.post(`/api/core/locales/${currentLocale}/keys`, { keysToAdd: missingTranslationsToAdd })
      // eslint-disable-next-line no-empty
    } catch (error) {}
    missingTranslationsToAdd = []
  }

  currentLocale = locale ?? localStorageLocale ?? navigator.language
  localStorage.setItem(localStorageKeys.LOCALE, currentLocale)
  htmlTag.setAttribute("data-rtl", currentLocale?.rtl || getLocaleParameters(currentLocale).rtl === true)

  const splittedLocale = currentLocale.split("-")
  const localeBaseCode = splittedLocale[0]
  const localeGeoCode = splittedLocale[1]
  const longLocale = `${localeBaseCode}-${(localeGeoCode || localeBaseCode).toUpperCase()}`
  isEnBaseCode = localeBaseCode === "en"

  try {
    await import(`moment/locale/${longLocale}`)
  } catch (error) {
    try {
      await import(`moment/locale/${localeBaseCode}`)
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  // To optimize startup time we do the calls in parallel.
  // We need to handle the following scenarios :
  //  - The user is not connected and lands on pages not requiring authentification like login, register-page etc.
  //    In this case we need to load the locale but not the string overrides.
  //    After he logs in we need to load the string overrides only.
  //  - The user lands directly connected : we need to load both.
  //  - The locale is the default one, we need to load on the string overrides if the user is connected.
  const loadLocaleStringsPromise = longLocale === defaultLocaleCode ? {} : getUriAndCacheResponse(`/api/core/locales/${longLocale}`)
  const loadStringOverridesPromise = loadStringOverrides
    ? getUriAndCacheResponse(`/api/script/runs/string-overrides${locale ? `?locale=${locale}` : ""}`)
    : {}

  let localeStrings
  try {
    localeStrings = await loadLocaleStringsPromise
  } catch (err) {
    // whenever an error occurs, fallback to the default locale
    localeStrings = {}
  }

  let stringOverrides
  try {
    stringOverrides = await loadStringOverridesPromise
  } catch (error) {
    // we cannot rely anymore on the useStandard flag because it is now loaded in parallel
    // so we never show an error message => commented out
    // if (isTenantUseStandard()) {
    // addOops(error)
    // }
    stringOverrides = {}
  }

  // The temporary key "TMP_KEY" is used to ensure that the translations (strings) of the current locale have been loaded
  // because the loc() function is called during the setLocale() (executions in // linked to the render I suppose)
  defaultLocalizedStrings = { ...localeStrings, TMP_KEY: currentLocale }
  localizedStrings = { ...localeStrings, ...stringOverrides, TMP_KEY: currentLocale }
}

export async function reloadLocales({ locales = [] } = {}) {
  if (!locales.length) locales.push(currentLocale)
  locales.forEach(locale => {
    removeCachedUriResponse(`/api/script/runs/string-overrides?locale=${locale}`)
  })
  removeCachedUriResponse(`/api/script/runs/string-overrides`)
  await setLocale({ locale: currentLocale })
}

export function resetLocale({ toCurrentLocale } = {}) {
  localizedStrings = defaultLocalizedStrings
  if (toCurrentLocale) {
    setLocale({ locale: currentLocale })
  }
}

async function addMissingTranslationsSync() {
  if (missingTranslationsToAdd.length) {
    axios.post(`/api/core/locales/${currentLocale}/keys`, { keysToAdd: missingTranslationsToAdd }) // no await
    missingTranslationsToAdd = []
  }
}

const addMissingTranslationsDebounced = debounce(addMissingTranslationsSync, 1000)

function isValidKey(key) {
  const _key = key.trim()
  if (isEmpty(_key) || !isNaN(_key + "0")) return false
  const keysToIgnore = [MISSING_TRANSLATION, "_insert date", "_update date", "??", "[object Object]", "YYMMDDXXXXX"]
  if (keysToIgnore.includes(key)) return false
  return true
}

export function loc(strings, ...values) {
  if (!strings) return strings

  const key = Array.isArray(strings) ? strings.join("$") : strings.toString()
  const localizedString = localizedStrings?.[key]
  let lstrings = !localizedString || localizedString === MISSING_TRANSLATION ? key : localizedString

  if (
    !localizedString &&
    !isEnBaseCode &&
    getCollectMissingTranslations() &&
    (envIsDevelopment() || envIsUat()) &&
    localizedStrings[TMP_KEY] === currentLocale
  ) {
    console.log(`Missing locale key "${key}" in "${currentLocale}"`)
    if (isValidKey(key)) missingTranslationsToAdd.push(key)
    addMissingTranslationsDebounced()
    localizedStrings[key] = lstrings // so that adding the missing translation is done only once
  }

  if (typeof lstrings === "string" && lstrings.includes("$")) lstrings = lstrings.split("$")

  // How can we enter this IF?
  if (!lstrings) {
    lstrings = strings
    if (strings.length === 1) lstrings = lstrings[0]
  }

  let lstr
  if (typeof lstrings === "string") lstr = lstrings
  else {
    const array = []
    for (let i = 0; i < values.length; i++) {
      array.push(lstrings[i], values[i])
    }
    array.push(lstrings[values.length])
    lstr = array.join("")
  }

  return lstr
}
