import React, { createRef } from "react"
import { loc } from "@/_services/localization"
import { FormControl, InputGroup } from "react-bootstrap"
import Select, { components } from "react-select"

class CountrySelectWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.containerRef = createRef()
    this.state = {
      isInputFocused: false,
      inputValue: "",
    }
  }

  componentDidMount() {
    this.containerRef.current?.addEventListener("mousedown", this.onDomClick)
  }

  componentWillUnmount() {
    this.containerRef.current?.removeEventListener("mousedown", this.onDomClick)
  }

  onDomClick = e => {
    const menu = this.containerRef.current?.querySelector(".select__menu")
    const containerHasTarget = this.containerRef.current?.contains(e.target)
    const menuHasTarget = menu?.contains(e.target)

    if (!containerHasTarget || !menu || !menuHasTarget) {
      this.setState({
        isInputFocused: false,
        inputValue: "",
      })
    }
  }

  handleInputFocus = () => {
    this.setState({ isInputFocused: true })
  }

  handleInputChange = text => {
    this.setState({ inputValue: text })
  }

  render() {
    const { fieldName, phoneNumberInfo, countryOptions, handleChange, disabled, commonFormControlProps } = this.props

    const { isInputFocused, inputValue } = this.state

    const selectProps = {
      countryCode: phoneNumberInfo?.countryCode || countryOptions.find(country => country.value === phoneNumberInfo?.callingCode)?.value2 || null,
      isDisabled: false,
    }

    return (
      <div style={{ display: "flex" }}>
        <div style={{ width: "min-content" }} ref={this.containerRef}>
          <Select
            {...selectProps}
            options={countryOptions}
            components={{
              MenuList,
              ValueContainer,
              SingleValue,
            }}
            classNamePrefix={`${fieldName}-select`}
            placeholder=""
            inputValue={inputValue}
            closeMenuOnSelect={true}
            name={`${fieldName}-emoji`}
            value={countryOptions.find(option => option.value === phoneNumberInfo?.callingCode)}
            onChange={ev => {
              this.setState({ isInputFocused: false })
              handleChange(ev)
            }}
            onInputFocus={this.handleInputFocus}
            onInputChange={this.handleInputChange}
            menuIsOpen={isInputFocused || undefined}
            isSearchable={false}
            styles={{
              control: css => ({
                ...css,
                minHeight: "36px",
                maxHeight: "36px",
                border: "1px solid var(--input-border-color)",
                width: "max-content",
              }),
              menu: css => ({ ...css, width: "240px" }),
              valueContainer: css => ({ ...css, fontSize: "20px" }),
              dropdownIndicator: css => ({ ...css, padding: 0 }),
            }}
          />
        </div>
        <InputGroup data-disabled={disabled}>
          <InputGroup.Addon className="form-input-addon">
            <span>{phoneNumberInfo?.callingCode || ""}</span>
          </InputGroup.Addon>
          <FormControl {...commonFormControlProps} />
        </InputGroup>
      </div>
    )
  }
}

export default CountrySelectWrapper

const MenuList = props => {
  const { inputValue, onInputChange, onInputFocus } = props.selectProps

  return (
    <div>
      <input
        title="non-search-field"
        className="w-100 pd-theme border-0 bbw-1"
        type="text"
        placeholder={loc("Search")}
        value={inputValue}
        onMouseDown={e => {
          e.stopPropagation()
          e.target.focus()
        }}
        onFocus={onInputFocus}
        onChange={e =>
          onInputChange(e.currentTarget.value, {
            action: "input-change",
            prevInputValue: inputValue,
          })
        }
      />
      <components.MenuList {...props} selectProps={props.selectProps} />
    </div>
  )
}

const ValueContainer = ({ children, selectProps, ...props }) => {
  const { ValueContainer } = components

  return (
    <ValueContainer {...props} selectProps={selectProps}>
      {React.Children.map(children, child => (child ? child : null))}
    </ValueContainer>
  )
}

const SingleValue = ({ selectProps, ...props }) => {
  const { SingleValue } = components

  return (
    <SingleValue {...props} selectProps={selectProps}>
      <i className={`icn-flag-${(selectProps?.countryCode || "").toLowerCase()} icn-flag`} />
    </SingleValue>
  )
}
